import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../components/Dropdown";

const Host = () => {
  const [selectedHost, setSelectedHost] = useState("");

  const hosts = useSelector((state) => state.company.data.hosts || []);
  const appointmentHostEmail = useSelector((state) => state.getAppointment.data.host_email);
  const userData = useSelector((state) => state.user.data.user);
  const visitors = useSelector((state) => state.visitors.data || []);

  const dispatch = useDispatch();

  const augmentedHosts = React.useMemo(() => {
    const allHosts = [...hosts];
    if (userData && !allHosts.some((host) => host.email === userData.email)) {
      allHosts.push({
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        azure_id: userData.azure_id,
      });
    }
    return allHosts;
  }, [hosts, userData]);

  useEffect(() => {
    if (appointmentHostEmail && appointmentHostEmail !== selectedHost) {
      setSelectedHost(appointmentHostEmail);
    } else if (!selectedHost && augmentedHosts.length > 0) {
      setSelectedHost(augmentedHosts[0].email);
    }
  }, [appointmentHostEmail, augmentedHosts, selectedHost]);

  useEffect(() => {
    if (selectedHost) {
      const hostData = augmentedHosts.find((host) => host.email === selectedHost);
      if (hostData) {
        dispatch({
          type: "SET_APPOINTMENT_DATA",
          payload: {
            host_teams_id: hostData.azure_id,
            host_email: hostData.email,
            host_name: `${hostData.first_name} ${hostData.last_name}`,
            creator_email: userData.email,
          },
        });
      }
    }
  }, [selectedHost, userData.email]);

  const dropdownOptions = React.useMemo(() => {
    return augmentedHosts
      .filter((host) => visitors.some((visitor) => visitor.email === host.email) || host.email === userData.email)
      .map((host) => ({
        label: host.first_name && host.last_name ? `${host.first_name} ${host.last_name}` : host.email,
        value: host.email,
      }));
  }, [augmentedHosts, visitors, userData.email]);

  return (
    <div>
      <Dropdown
        label="Gastgeber"
        options={dropdownOptions}
        value={selectedHost}
        onChange={(value) => setSelectedHost(value)}
      />
    </div>
  );
};

export default Host;
