import * as React from "react";

const Input = ({ label, className, ...props }) => {
  return (
    <>
      {label && <span className="block text-sm font-inter_semibold mt-2">{label}</span>}
      <div className="relative font-inter_regular">
        <input
          className={`relative mt-2 w-full rounded-md bg-white py-1.5 px-3 border border-lightgrey text-sm ${className}`}
          {...props}
        />
      </div>
    </>
  );
};

export default Input;
