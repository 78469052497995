/*! @azure/msal-common v14.13.0 2024-07-01 */
'use strict';

/* eslint-disable header/header */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.version = exports.name = void 0;
var name = exports.name = "@azure/msal-common";
var version = exports.version = "14.13.0";
