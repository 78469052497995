import axios from "axios";
import { call, put, select } from "redux-saga/effects";
import authSaga from "./authSaga";

function* graphAuthSaga() {
  const URL = "https://api.screencom.ch";

  try {
    let token = yield select((state) => state.user.token);

    if (!token) {
      token = yield call(authSaga, { type: "Graph_Mail" });
      if (token) {
        yield put({ type: "SET_TOKEN", payload: token });
      } else {
        throw new Error("Failed to fetch access token for user permissions.");
      }
    }

    const response = yield call(axios.post, `${URL}/token/list`, {
      customer_id: 9,
      token_type: "Graph-Mail",
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.status !== 200) {
      throw new Error(`Failed to fetch access token from token list API: ${response.status} ${response.statusText}`);
    }

    return response.data.token;

  } catch (error) {
    console.error("Error fetching access token:", error);
    throw error;
  }
}

export default graphAuthSaga;
