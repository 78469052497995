
import * as React from "react";

const Dropdown = ({ label, options, onChange, className, value, placeholder, ...props }) => {
  const handleOptionChange = (e) => {
    const selectedValue = e.target.value;
    onChange(selectedValue);
  };

  const selectStyle = {
    color: value ? "initial" : "#A4A7B3",
  };

  return (
    <div className="relative">
      {label && <span className="block text-sm font-inter_semibold mt-2">{label}</span>}
      <select
        className={`block w-full py-1.5 pl-3 mt-2 font-inter_regular rounded-md shadow-sm ring-1 ring-lightgrey text-sm appearance-none ${className}`}
        value={value || ""}
        onChange={handleOptionChange}
        style={selectStyle}
        {...props}
      >
        {!value && placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value} style={{ color: "black" }}>
            {option.label}
          </option>
        ))}
      </select>
      <div className={`pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 ${label ? "pt-7" : ""}`}>

      </div>
    </div>
  );
};

export default Dropdown;
