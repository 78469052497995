/*! @azure/msal-browser v2.39.0 2024-06-06 */
'use strict';

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AccountEntity", {
  enumerable: true,
  get: function get() {
    return _msalCommon.AccountEntity;
  }
});
Object.defineProperty(exports, "ApiId", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.ApiId;
  }
});
Object.defineProperty(exports, "AuthError", {
  enumerable: true,
  get: function get() {
    return _msalCommon.AuthError;
  }
});
Object.defineProperty(exports, "AuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _msalCommon.AuthErrorMessage;
  }
});
Object.defineProperty(exports, "AuthenticationHeaderParser", {
  enumerable: true,
  get: function get() {
    return _msalCommon.AuthenticationHeaderParser;
  }
});
Object.defineProperty(exports, "AuthenticationScheme", {
  enumerable: true,
  get: function get() {
    return _msalCommon.AuthenticationScheme;
  }
});
Object.defineProperty(exports, "AzureCloudInstance", {
  enumerable: true,
  get: function get() {
    return _msalCommon.AzureCloudInstance;
  }
});
Object.defineProperty(exports, "BrowserAuthError", {
  enumerable: true,
  get: function get() {
    return _BrowserAuthError.BrowserAuthError;
  }
});
Object.defineProperty(exports, "BrowserAuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _BrowserAuthError.BrowserAuthErrorMessage;
  }
});
Object.defineProperty(exports, "BrowserCacheLocation", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.BrowserCacheLocation;
  }
});
Object.defineProperty(exports, "BrowserConfigurationAuthError", {
  enumerable: true,
  get: function get() {
    return _BrowserConfigurationAuthError.BrowserConfigurationAuthError;
  }
});
Object.defineProperty(exports, "BrowserConfigurationAuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _BrowserConfigurationAuthError.BrowserConfigurationAuthErrorMessage;
  }
});
Object.defineProperty(exports, "BrowserUtils", {
  enumerable: true,
  get: function get() {
    return _BrowserUtils.BrowserUtils;
  }
});
Object.defineProperty(exports, "CacheLookupPolicy", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.CacheLookupPolicy;
  }
});
Object.defineProperty(exports, "ClientAuthError", {
  enumerable: true,
  get: function get() {
    return _msalCommon.ClientAuthError;
  }
});
Object.defineProperty(exports, "ClientAuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _msalCommon.ClientAuthErrorMessage;
  }
});
Object.defineProperty(exports, "ClientConfigurationError", {
  enumerable: true,
  get: function get() {
    return _msalCommon.ClientConfigurationError;
  }
});
Object.defineProperty(exports, "ClientConfigurationErrorMessage", {
  enumerable: true,
  get: function get() {
    return _msalCommon.ClientConfigurationErrorMessage;
  }
});
Object.defineProperty(exports, "DEFAULT_IFRAME_TIMEOUT_MS", {
  enumerable: true,
  get: function get() {
    return _Configuration.DEFAULT_IFRAME_TIMEOUT_MS;
  }
});
Object.defineProperty(exports, "EventMessageUtils", {
  enumerable: true,
  get: function get() {
    return _EventMessage.EventMessageUtils;
  }
});
Object.defineProperty(exports, "EventType", {
  enumerable: true,
  get: function get() {
    return _EventType.EventType;
  }
});
Object.defineProperty(exports, "InteractionRequiredAuthError", {
  enumerable: true,
  get: function get() {
    return _msalCommon.InteractionRequiredAuthError;
  }
});
Object.defineProperty(exports, "InteractionRequiredAuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _msalCommon.InteractionRequiredAuthErrorMessage;
  }
});
Object.defineProperty(exports, "InteractionStatus", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.InteractionStatus;
  }
});
Object.defineProperty(exports, "InteractionType", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.InteractionType;
  }
});
Object.defineProperty(exports, "LogLevel", {
  enumerable: true,
  get: function get() {
    return _msalCommon.LogLevel;
  }
});
Object.defineProperty(exports, "Logger", {
  enumerable: true,
  get: function get() {
    return _msalCommon.Logger;
  }
});
Object.defineProperty(exports, "NavigationClient", {
  enumerable: true,
  get: function get() {
    return _NavigationClient.NavigationClient;
  }
});
Object.defineProperty(exports, "OIDC_DEFAULT_SCOPES", {
  enumerable: true,
  get: function get() {
    return _msalCommon.OIDC_DEFAULT_SCOPES;
  }
});
Object.defineProperty(exports, "PerformanceEvents", {
  enumerable: true,
  get: function get() {
    return _msalCommon.PerformanceEvents;
  }
});
Object.defineProperty(exports, "ProtocolMode", {
  enumerable: true,
  get: function get() {
    return _msalCommon.ProtocolMode;
  }
});
Object.defineProperty(exports, "PublicClientApplication", {
  enumerable: true,
  get: function get() {
    return _PublicClientApplication.PublicClientApplication;
  }
});
Object.defineProperty(exports, "ServerError", {
  enumerable: true,
  get: function get() {
    return _msalCommon.ServerError;
  }
});
Object.defineProperty(exports, "SignedHttpRequest", {
  enumerable: true,
  get: function get() {
    return _SignedHttpRequest.SignedHttpRequest;
  }
});
Object.defineProperty(exports, "StringUtils", {
  enumerable: true,
  get: function get() {
    return _msalCommon.StringUtils;
  }
});
Object.defineProperty(exports, "UrlString", {
  enumerable: true,
  get: function get() {
    return _msalCommon.UrlString;
  }
});
Object.defineProperty(exports, "WrapperSKU", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.WrapperSKU;
  }
});
exports.internals = void 0;
Object.defineProperty(exports, "stubbedPublicClientApplication", {
  enumerable: true,
  get: function get() {
    return _IPublicClientApplication.stubbedPublicClientApplication;
  }
});
Object.defineProperty(exports, "version", {
  enumerable: true,
  get: function get() {
    return _packageMetadata.version;
  }
});
var internals = _interopRequireWildcard(require("./internals.js"));
exports.internals = internals;
var _PublicClientApplication = require("./app/PublicClientApplication.js");
var _Configuration = require("./config/Configuration.js");
var _BrowserConstants = require("./utils/BrowserConstants.js");
var _BrowserUtils = require("./utils/BrowserUtils.js");
var _BrowserAuthError = require("./error/BrowserAuthError.js");
var _BrowserConfigurationAuthError = require("./error/BrowserConfigurationAuthError.js");
var _IPublicClientApplication = require("./app/IPublicClientApplication.js");
var _NavigationClient = require("./navigation/NavigationClient.js");
var _EventMessage = require("./event/EventMessage.js");
var _EventType = require("./event/EventType.js");
var _SignedHttpRequest = require("./crypto/SignedHttpRequest.js");
var _msalCommon = require("@azure/msal-common");
var _packageMetadata = require("./packageMetadata.js");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
