import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'raf/polyfill';
import 'fast-text-encoding';

// Polyfill for TextEncoder and TextDecoder
if (typeof TextEncoder === 'undefined') {
  global.TextEncoder = require('fast-text-encoding').TextEncoder;
}
if (typeof TextDecoder === 'undefined') {
  global.TextDecoder = require('fast-text-encoding').TextDecoder;
}

// Ensure process.env is defined
if (typeof process === 'undefined') {
  global.process = {
    env: {}
  };
}


if (typeof Error.captureStackTrace === 'undefined') {
    Error.captureStackTrace = function (error) {
      error.stack = (new Error()).stack;
    };
  }
  

  if (!Array.prototype.isEmpty) {
    Array.prototype.isEmpty = function() {
      return this.length === 0;
    };
  }
  
  if (!String.prototype.isEmpty) {
    String.prototype.isEmpty = function() {
      return this.length === 0;
    };
  }
  

  