import * as Msal from "msal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./components/Header";
import Spinner from "./components/Spinner";
import LoginFailed from "./pages/LoginFailed";
import Portier from "./pages/Portier";
import { FETCH_PERMISSIONS_USER_REQUEST, GET_COMPANY_DATA_REQUEST, SET_TOKEN } from "./redux/actions/index";

if (!Array.prototype.isEmpty) {
  Array.prototype.isEmpty = function() {
    return this.length === 0;
  };
}

if (!String.prototype.isEmpty) {
  String.prototype.isEmpty = function() {
    return this.length === null;
  };
}

const msalConfig = {
  auth: {
    clientId: 'dbcc2a69-20ba-44f9-9bf9-6f9ceee57f3b',
    authority: 'https://login.microsoftonline.com/common/',
    redirectUri: 'https://addin-2016.eportier.ch',  // Dies muss mit der registrierten Redirect-URL übereinstimmen
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

const msalInstance = new Msal.UserAgentApplication(msalConfig);

const App = ({ officeReady }) => {
  const [isTokenRequestInProgress, setIsTokenRequestInProgress] = useState(false);
  const [msalReady, setMsalReady] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const token = useSelector((state) => state.user.token);

  useEffect(() => {
    msalInstance.handleRedirectCallback((error, response) => {
      if (error) {
        console.error("Redirect Error: ", error);
        alert("Redirect Error: " + error.message);
      } else {
        if (response) {
          console.log("Redirect Response: ", response);
          const accessToken = response.accessToken;
          console.log("Access Token: ", accessToken);
          dispatch({ type: SET_TOKEN, payload: accessToken });

          if (window.opener) {
            window.opener.postMessage({ type: 'msal:tokenReceived', accessToken }, '*');
            window.close();
          }
        }
      }
    });

    if (msalInstance.getAccount() && !token && !isTokenRequestInProgress) {
      console.log("User is already logged in, acquiring token silently");
      acquireToken();
    } else if (officeReady && msalReady && !isTokenRequestInProgress && !token) {
      console.log("Attempting SSO login");
      trySSOLogin();
    }
  }, [officeReady, msalReady, isTokenRequestInProgress, token]);

  useEffect(() => {
    console.log("Loaded App");
    setMsalReady(true);
  }, []);

  const trySSOLogin = async () => {
    setIsTokenRequestInProgress(true);
    const loginRequest = {
      scopes: ["api://addin.eportier.ch/access_as_user", "openid", "profile", "email", "Mail.Send"],
    };
    try {
      console.log('Initiating loginRedirect');
      await msalInstance.loginRedirect(loginRequest);
      console.log('loginRedirect initiated');
    } catch (msalError) {
      console.error("MSAL Login failed:", msalError);
      console.error('Error stack trace:', msalError.stack);
      console.error('Error message:', msalError.message);
    }
    setIsTokenRequestInProgress(false);
  };

  const acquireToken = async () => {
    setIsTokenRequestInProgress(true);
    const tokenRequest = {
      scopes: ["api://addin.eportier.ch/access_as_user", "openid", "profile", "email", "Mail.Send"],
    };
    try {
      const response = await msalInstance.acquireTokenSilent(tokenRequest);
      console.log("Token Response: ", response);
      const accessToken = response.accessToken;
      console.log("Access Token: ", accessToken);
      dispatch({ type: SET_TOKEN, payload: accessToken });
      dispatch(FETCH_PERMISSIONS_USER_REQUEST());
    } catch (error) {
      console.error("Silent Token Acquisition failed: ", error);
      fallbackLogin();
    }
    setIsTokenRequestInProgress(false);
  };

  const fallbackLogin = async () => {
    const loginRequest = {
      scopes: ["api://addin.eportier.ch/access_as_user", "openid", "profile", "email", "Mail.Send"],
    };
    try {
      console.log('Initiating loginRedirect');
      await msalInstance.loginRedirect(loginRequest);
      console.log('loginRedirect initiated');
    } catch (msalError) {
      console.error("MSAL Login failed:", msalError);
      console.error('Error stack trace:', msalError.stack);
      console.error('Error message:', msalError.message);
    }
    setIsTokenRequestInProgress(false);
  };

  const hasAccess =
    user.loaded &&
    user.data.permissions &&
    user.data.permissions.some((permission) =>
      ["Admin", "Visitor.Admin", "Visitor.Manager", "Visitor.User"].includes(permission.role_name),
    );

  useEffect(() => {
    if (user.data && hasAccess && token) {
      dispatch(GET_COMPANY_DATA_REQUEST({}));
    }
  }, [user.data, hasAccess, token]);

  return (
    <div>
      <Header />
      {user ? user.loaded ? hasAccess ? <Portier /> : <LoginFailed /> : <Spinner /> : <h1>Anmeldung erforderlich</h1>}
    </div>
  );
};

export default App;
