import React from "react";

const AppointmentFailed = () => {
  return (
    <div>
      <div className="flex h-[calc(100vh-160px)] items-center justify-center p-5 w-full bg-white">
        <div className="text-center">
          <div className="inline-flex rounded-full bg-red-100 p-3">
            <div className="rounded-full stroke-red-600 bg-red-100 p-3">
              <svg className="w-10 h-10" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.0002 9.33337V14M14.0002 18.6667H14.0118M25.6668 14C25.6668 20.4434 20.4435 25.6667 14.0002 25.6667C7.55684 25.6667 2.3335 20.4434 2.3335 14C2.3335 7.55672 7.55684 2.33337 14.0002 2.33337C20.4435 2.33337 25.6668 7.55672 25.6668 14Z"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>
              </svg>
            </div>
          </div>
          <h1 className="mt-5 font-inter_bold text-2xl">Fehler beim Erstellen des Termins</h1>
          <p className="font-inter_regular mt-5 text-base">
            Leider konnte der Termin nicht erstellt werden. Bitte versuchen Sie es später noch einmal.
          </p>
          <p className="font-inter_regular mt-5 text-base">
            Bei weiteren Problemen kontaktieren Sie bitte unseren Support:{" "}
            <b>
              <a href="mailto:servicedesk@vocom.ch?subject=ePortier%20Outlook%20Add-In%20/%20Support">
                servicedesk@vocom.ch
              </a>
            </b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AppointmentFailed;
