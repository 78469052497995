"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof3 = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.connect = exports.batch = exports.ReactReduxContext = exports.Provider = void 0;
exports.createDispatchHook = createDispatchHook;
exports.createSelectorHook = createSelectorHook;
exports.createStoreHook = createStoreHook;
exports.shallowEqual = shallowEqual;
exports.useStore = exports.useSelector = exports.useDispatch = void 0;
var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));
var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var React2 = _interopRequireWildcard(require("react"));
var ReactOriginal = React2;
var _withSelector = require("use-sync-external-store/with-selector.js");
var _excluded = ["initMapStateToProps", "initMapDispatchToProps", "initMergeProps"],
  _excluded2 = ["reactReduxForwardedRef"];
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof3(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // src/index.ts
// src/utils/react.ts
var React =
// prettier-ignore
// @ts-ignore
"default" in ReactOriginal ? ReactOriginal["default"] : ReactOriginal;

// src/components/Context.ts
var ContextKey = Symbol.for("react-redux-context");
var gT = typeof globalThis !== "undefined" ? globalThis : ( /* fall back to a per-module scope (pre-8.1 behaviour) if `globalThis` is not available */
{});
function getContext() {
  var _gT$ContextKey;
  if (!React.createContext) return {};
  var contextMap = (_gT$ContextKey = gT[ContextKey]) !== null && _gT$ContextKey !== void 0 ? _gT$ContextKey : gT[ContextKey] = /* @__PURE__ */new Map();
  var realContext = contextMap.get(React.createContext);
  if (!realContext) {
    realContext = React.createContext(null);
    if (process.env.NODE_ENV !== "production") {
      realContext.displayName = "ReactRedux";
    }
    contextMap.set(React.createContext, realContext);
  }
  return realContext;
}
var ReactReduxContext = exports.ReactReduxContext = /* @__PURE__ */getContext();

// src/utils/useSyncExternalStore.ts
var notInitialized = function notInitialized() {
  throw new Error("uSES not initialized!");
};

// src/hooks/useReduxContext.ts
function createReduxContextHook() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReactReduxContext;
  return function useReduxContext2() {
    var contextValue = React.useContext(context);
    if (process.env.NODE_ENV !== "production" && !contextValue) {
      throw new Error("could not find react-redux context value; please ensure the component is wrapped in a <Provider>");
    }
    return contextValue;
  };
}
var useReduxContext = /* @__PURE__ */createReduxContextHook();

// src/hooks/useSelector.ts
var useSyncExternalStoreWithSelector = notInitialized;
var initializeUseSelector = function initializeUseSelector(fn) {
  useSyncExternalStoreWithSelector = fn;
};
var refEquality = function refEquality(a, b) {
  return a === b;
};
function createSelectorHook() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReactReduxContext;
  var useReduxContext2 = context === ReactReduxContext ? useReduxContext : createReduxContextHook(context);
  var useSelector2 = function useSelector2(selector) {
    var equalityFnOrOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var _ref = typeof equalityFnOrOptions === "function" ? {
        equalityFn: equalityFnOrOptions
      } : equalityFnOrOptions,
      _ref$equalityFn = _ref.equalityFn,
      equalityFn = _ref$equalityFn === void 0 ? refEquality : _ref$equalityFn,
      _ref$devModeChecks = _ref.devModeChecks,
      devModeChecks = _ref$devModeChecks === void 0 ? {} : _ref$devModeChecks;
    if (process.env.NODE_ENV !== "production") {
      if (!selector) {
        throw new Error("You must pass a selector to useSelector");
      }
      if (typeof selector !== "function") {
        throw new Error("You must pass a function as a selector to useSelector");
      }
      if (typeof equalityFn !== "function") {
        throw new Error("You must pass a function as an equality function to useSelector");
      }
    }
    var _useReduxContext = useReduxContext2(),
      store = _useReduxContext.store,
      subscription = _useReduxContext.subscription,
      getServerState = _useReduxContext.getServerState,
      stabilityCheck = _useReduxContext.stabilityCheck,
      identityFunctionCheck = _useReduxContext.identityFunctionCheck;
    var firstRun = React.useRef(true);
    var wrappedSelector = React.useCallback((0, _defineProperty2.default)({}, selector.name, function (state) {
      var selected = selector(state);
      if (process.env.NODE_ENV !== "production") {
        var _stabilityCheck$ident = _objectSpread({
            stabilityCheck: stabilityCheck,
            identityFunctionCheck: identityFunctionCheck
          }, devModeChecks),
          finalIdentityFunctionCheck = _stabilityCheck$ident.identityFunctionCheck,
          finalStabilityCheck = _stabilityCheck$ident.stabilityCheck;
        if (finalStabilityCheck === "always" || finalStabilityCheck === "once" && firstRun.current) {
          var toCompare = selector(state);
          if (!equalityFn(selected, toCompare)) {
            var stack = void 0;
            try {
              throw new Error();
            } catch (e) {
              ;
              stack = e.stack;
            }
            console.warn("Selector " + (selector.name || "unknown") + " returned a different result when called with the same parameters. This can lead to unnecessary rerenders.\nSelectors that return a new reference (such as an object or an array) should be memoized: https://redux.js.org/usage/deriving-data-selectors#optimizing-selectors-with-memoization", {
              state: state,
              selected: selected,
              selected2: toCompare,
              stack: stack
            });
          }
        }
        if (finalIdentityFunctionCheck === "always" || finalIdentityFunctionCheck === "once" && firstRun.current) {
          if (selected === state) {
            var _stack = void 0;
            try {
              throw new Error();
            } catch (e) {
              ;
              _stack = e.stack;
            }
            console.warn("Selector " + (selector.name || "unknown") + " returned the root state when called. This can lead to unnecessary rerenders.\nSelectors that return the entire state are almost certainly a mistake, as they will cause a rerender whenever *anything* in state changes.", {
              stack: _stack
            });
          }
        }
        if (firstRun.current) firstRun.current = false;
      }
      return selected;
    })[selector.name], [selector, stabilityCheck, devModeChecks.stabilityCheck]);
    var selectedState = useSyncExternalStoreWithSelector(subscription.addNestedSub, store.getState, getServerState || store.getState, wrappedSelector, equalityFn);
    React.useDebugValue(selectedState);
    return selectedState;
  };
  Object.assign(useSelector2, {
    withTypes: function withTypes() {
      return useSelector2;
    }
  });
  return useSelector2;
}
var useSelector = exports.useSelector = /* @__PURE__ */createSelectorHook();

// src/utils/react-is.ts
var REACT_ELEMENT_TYPE = Symbol.for("react.element");
var REACT_PORTAL_TYPE = Symbol.for("react.portal");
var REACT_FRAGMENT_TYPE = Symbol.for("react.fragment");
var REACT_STRICT_MODE_TYPE = Symbol.for("react.strict_mode");
var REACT_PROFILER_TYPE = Symbol.for("react.profiler");
var REACT_PROVIDER_TYPE = Symbol.for("react.provider");
var REACT_CONTEXT_TYPE = Symbol.for("react.context");
var REACT_SERVER_CONTEXT_TYPE = Symbol.for("react.server_context");
var REACT_FORWARD_REF_TYPE = Symbol.for("react.forward_ref");
var REACT_SUSPENSE_TYPE = Symbol.for("react.suspense");
var REACT_SUSPENSE_LIST_TYPE = Symbol.for("react.suspense_list");
var REACT_MEMO_TYPE = Symbol.for("react.memo");
var REACT_LAZY_TYPE = Symbol.for("react.lazy");
var REACT_OFFSCREEN_TYPE = Symbol.for("react.offscreen");
var REACT_CLIENT_REFERENCE = Symbol.for("react.client.reference");
var ForwardRef = REACT_FORWARD_REF_TYPE;
var Memo = REACT_MEMO_TYPE;
function isValidElementType(type) {
  if (typeof type === "string" || typeof type === "function") {
    return true;
  }
  if (type === REACT_FRAGMENT_TYPE || type === REACT_PROFILER_TYPE || type === REACT_STRICT_MODE_TYPE || type === REACT_SUSPENSE_TYPE || type === REACT_SUSPENSE_LIST_TYPE || type === REACT_OFFSCREEN_TYPE) {
    return true;
  }
  if ((0, _typeof2.default)(type) === "object" && type !== null) {
    if (type.$$typeof === REACT_LAZY_TYPE || type.$$typeof === REACT_MEMO_TYPE || type.$$typeof === REACT_PROVIDER_TYPE || type.$$typeof === REACT_CONTEXT_TYPE || type.$$typeof === REACT_FORWARD_REF_TYPE ||
    // This needs to include all possible module reference object
    // types supported by any Flight configuration anywhere since
    // we don't know which Flight build this will end up being used
    // with.
    type.$$typeof === REACT_CLIENT_REFERENCE || type.getModuleId !== void 0) {
      return true;
    }
  }
  return false;
}
function typeOf(object) {
  if ((0, _typeof2.default)(object) === "object" && object !== null) {
    var $$typeof = object.$$typeof;
    switch ($$typeof) {
      case REACT_ELEMENT_TYPE:
        {
          var type = object.type;
          switch (type) {
            case REACT_FRAGMENT_TYPE:
            case REACT_PROFILER_TYPE:
            case REACT_STRICT_MODE_TYPE:
            case REACT_SUSPENSE_TYPE:
            case REACT_SUSPENSE_LIST_TYPE:
              return type;
            default:
              {
                var $$typeofType = type && type.$$typeof;
                switch ($$typeofType) {
                  case REACT_SERVER_CONTEXT_TYPE:
                  case REACT_CONTEXT_TYPE:
                  case REACT_FORWARD_REF_TYPE:
                  case REACT_LAZY_TYPE:
                  case REACT_MEMO_TYPE:
                  case REACT_PROVIDER_TYPE:
                    return $$typeofType;
                  default:
                    return $$typeof;
                }
              }
          }
        }
      case REACT_PORTAL_TYPE:
        {
          return $$typeof;
        }
    }
  }
  return void 0;
}
function isContextConsumer(object) {
  return typeOf(object) === REACT_CONTEXT_TYPE;
}
function isMemo(object) {
  return typeOf(object) === REACT_MEMO_TYPE;
}

// src/utils/warning.ts
function warning(message) {
  if (typeof console !== "undefined" && typeof console.error === "function") {
    console.error(message);
  }
  try {
    throw new Error(message);
  } catch (e) {}
}

// src/connect/verifySubselectors.ts
function verify(selector, methodName) {
  if (!selector) {
    throw new Error("Unexpected value for ".concat(methodName, " in connect."));
  } else if (methodName === "mapStateToProps" || methodName === "mapDispatchToProps") {
    if (!Object.prototype.hasOwnProperty.call(selector, "dependsOnOwnProps")) {
      warning("The selector for ".concat(methodName, " of connect did not specify a value for dependsOnOwnProps."));
    }
  }
}
function verifySubselectors(mapStateToProps, mapDispatchToProps, mergeProps) {
  verify(mapStateToProps, "mapStateToProps");
  verify(mapDispatchToProps, "mapDispatchToProps");
  verify(mergeProps, "mergeProps");
}

// src/connect/selectorFactory.ts
function pureFinalPropsSelectorFactory(mapStateToProps, mapDispatchToProps, mergeProps, dispatch, _ref2) {
  var areStatesEqual = _ref2.areStatesEqual,
    areOwnPropsEqual = _ref2.areOwnPropsEqual,
    areStatePropsEqual = _ref2.areStatePropsEqual;
  var hasRunAtLeastOnce = false;
  var state;
  var ownProps;
  var stateProps;
  var dispatchProps;
  var mergedProps;
  function handleFirstCall(firstState, firstOwnProps) {
    state = firstState;
    ownProps = firstOwnProps;
    stateProps = mapStateToProps(state, ownProps);
    dispatchProps = mapDispatchToProps(dispatch, ownProps);
    mergedProps = mergeProps(stateProps, dispatchProps, ownProps);
    hasRunAtLeastOnce = true;
    return mergedProps;
  }
  function handleNewPropsAndNewState() {
    stateProps = mapStateToProps(state, ownProps);
    if (mapDispatchToProps.dependsOnOwnProps) dispatchProps = mapDispatchToProps(dispatch, ownProps);
    mergedProps = mergeProps(stateProps, dispatchProps, ownProps);
    return mergedProps;
  }
  function handleNewProps() {
    if (mapStateToProps.dependsOnOwnProps) stateProps = mapStateToProps(state, ownProps);
    if (mapDispatchToProps.dependsOnOwnProps) dispatchProps = mapDispatchToProps(dispatch, ownProps);
    mergedProps = mergeProps(stateProps, dispatchProps, ownProps);
    return mergedProps;
  }
  function handleNewState() {
    var nextStateProps = mapStateToProps(state, ownProps);
    var statePropsChanged = !areStatePropsEqual(nextStateProps, stateProps);
    stateProps = nextStateProps;
    if (statePropsChanged) mergedProps = mergeProps(stateProps, dispatchProps, ownProps);
    return mergedProps;
  }
  function handleSubsequentCalls(nextState, nextOwnProps) {
    var propsChanged = !areOwnPropsEqual(nextOwnProps, ownProps);
    var stateChanged = !areStatesEqual(nextState, state, nextOwnProps, ownProps);
    state = nextState;
    ownProps = nextOwnProps;
    if (propsChanged && stateChanged) return handleNewPropsAndNewState();
    if (propsChanged) return handleNewProps();
    if (stateChanged) return handleNewState();
    return mergedProps;
  }
  return function pureFinalPropsSelector(nextState, nextOwnProps) {
    return hasRunAtLeastOnce ? handleSubsequentCalls(nextState, nextOwnProps) : handleFirstCall(nextState, nextOwnProps);
  };
}
function finalPropsSelectorFactory(dispatch, _ref3) {
  var initMapStateToProps = _ref3.initMapStateToProps,
    initMapDispatchToProps = _ref3.initMapDispatchToProps,
    initMergeProps = _ref3.initMergeProps,
    options = (0, _objectWithoutProperties2.default)(_ref3, _excluded);
  var mapStateToProps = initMapStateToProps(dispatch, options);
  var mapDispatchToProps = initMapDispatchToProps(dispatch, options);
  var mergeProps = initMergeProps(dispatch, options);
  if (process.env.NODE_ENV !== "production") {
    verifySubselectors(mapStateToProps, mapDispatchToProps, mergeProps);
  }
  return pureFinalPropsSelectorFactory(mapStateToProps, mapDispatchToProps, mergeProps, dispatch, options);
}

// src/utils/bindActionCreators.ts
function bindActionCreators(actionCreators, dispatch) {
  var boundActionCreators = {};
  var _loop = function _loop() {
    var actionCreator = actionCreators[key];
    if (typeof actionCreator === "function") {
      boundActionCreators[key] = function () {
        return dispatch(actionCreator.apply(void 0, arguments));
      };
    }
  };
  for (var key in actionCreators) {
    _loop();
  }
  return boundActionCreators;
}

// src/utils/isPlainObject.ts
function isPlainObject(obj) {
  if ((0, _typeof2.default)(obj) !== "object" || obj === null) return false;
  var proto = Object.getPrototypeOf(obj);
  if (proto === null) return true;
  var baseProto = proto;
  while (Object.getPrototypeOf(baseProto) !== null) {
    baseProto = Object.getPrototypeOf(baseProto);
  }
  return proto === baseProto;
}

// src/utils/verifyPlainObject.ts
function verifyPlainObject(value, displayName, methodName) {
  if (!isPlainObject(value)) {
    warning("".concat(methodName, "() in ").concat(displayName, " must return a plain object. Instead received ").concat(value, "."));
  }
}

// src/connect/wrapMapToProps.ts
function wrapMapToPropsConstant(getConstant) {
  return function initConstantSelector(dispatch) {
    var constant = getConstant(dispatch);
    function constantSelector() {
      return constant;
    }
    constantSelector.dependsOnOwnProps = false;
    return constantSelector;
  };
}
function getDependsOnOwnProps(mapToProps) {
  return mapToProps.dependsOnOwnProps ? Boolean(mapToProps.dependsOnOwnProps) : mapToProps.length !== 1;
}
function wrapMapToPropsFunc(mapToProps, methodName) {
  return function initProxySelector(dispatch, _ref4) {
    var displayName = _ref4.displayName;
    var proxy = function mapToPropsProxy(stateOrDispatch, ownProps) {
      return proxy.dependsOnOwnProps ? proxy.mapToProps(stateOrDispatch, ownProps) : proxy.mapToProps(stateOrDispatch, void 0);
    };
    proxy.dependsOnOwnProps = true;
    proxy.mapToProps = function detectFactoryAndVerify(stateOrDispatch, ownProps) {
      proxy.mapToProps = mapToProps;
      proxy.dependsOnOwnProps = getDependsOnOwnProps(mapToProps);
      var props = proxy(stateOrDispatch, ownProps);
      if (typeof props === "function") {
        proxy.mapToProps = props;
        proxy.dependsOnOwnProps = getDependsOnOwnProps(props);
        props = proxy(stateOrDispatch, ownProps);
      }
      if (process.env.NODE_ENV !== "production") verifyPlainObject(props, displayName, methodName);
      return props;
    };
    return proxy;
  };
}

// src/connect/invalidArgFactory.ts
function createInvalidArgFactory(arg, name) {
  return function (dispatch, options) {
    throw new Error("Invalid value of type ".concat((0, _typeof2.default)(arg), " for ").concat(name, " argument when connecting component ").concat(options.wrappedComponentName, "."));
  };
}

// src/connect/mapDispatchToProps.ts
function mapDispatchToPropsFactory(mapDispatchToProps) {
  return mapDispatchToProps && (0, _typeof2.default)(mapDispatchToProps) === "object" ? wrapMapToPropsConstant(function (dispatch) {
    return (
      // @ts-ignore
      bindActionCreators(mapDispatchToProps, dispatch)
    );
  }) : !mapDispatchToProps ? wrapMapToPropsConstant(function (dispatch) {
    return {
      dispatch: dispatch
    };
  }) : typeof mapDispatchToProps === "function" ?
  // @ts-ignore
  wrapMapToPropsFunc(mapDispatchToProps, "mapDispatchToProps") : createInvalidArgFactory(mapDispatchToProps, "mapDispatchToProps");
}

// src/connect/mapStateToProps.ts
function mapStateToPropsFactory(mapStateToProps) {
  return !mapStateToProps ? wrapMapToPropsConstant(function () {
    return {};
  }) : typeof mapStateToProps === "function" ?
  // @ts-ignore
  wrapMapToPropsFunc(mapStateToProps, "mapStateToProps") : createInvalidArgFactory(mapStateToProps, "mapStateToProps");
}

// src/connect/mergeProps.ts
function defaultMergeProps(stateProps, dispatchProps, ownProps) {
  return _objectSpread(_objectSpread(_objectSpread({}, ownProps), stateProps), dispatchProps);
}
function wrapMergePropsFunc(mergeProps) {
  return function initMergePropsProxy(dispatch, _ref5) {
    var displayName = _ref5.displayName,
      areMergedPropsEqual = _ref5.areMergedPropsEqual;
    var hasRunOnce = false;
    var mergedProps;
    return function mergePropsProxy(stateProps, dispatchProps, ownProps) {
      var nextMergedProps = mergeProps(stateProps, dispatchProps, ownProps);
      if (hasRunOnce) {
        if (!areMergedPropsEqual(nextMergedProps, mergedProps)) mergedProps = nextMergedProps;
      } else {
        hasRunOnce = true;
        mergedProps = nextMergedProps;
        if (process.env.NODE_ENV !== "production") verifyPlainObject(mergedProps, displayName, "mergeProps");
      }
      return mergedProps;
    };
  };
}
function mergePropsFactory(mergeProps) {
  return !mergeProps ? function () {
    return defaultMergeProps;
  } : typeof mergeProps === "function" ? wrapMergePropsFunc(mergeProps) : createInvalidArgFactory(mergeProps, "mergeProps");
}

// src/utils/batch.ts
function defaultNoopBatch(callback) {
  callback();
}

// src/utils/Subscription.ts
function createListenerCollection() {
  var first = null;
  var last = null;
  return {
    clear: function clear() {
      first = null;
      last = null;
    },
    notify: function notify() {
      defaultNoopBatch(function () {
        var listener = first;
        while (listener) {
          listener.callback();
          listener = listener.next;
        }
      });
    },
    get: function get() {
      var listeners = [];
      var listener = first;
      while (listener) {
        listeners.push(listener);
        listener = listener.next;
      }
      return listeners;
    },
    subscribe: function subscribe(callback) {
      var isSubscribed = true;
      var listener = last = {
        callback: callback,
        next: null,
        prev: last
      };
      if (listener.prev) {
        listener.prev.next = listener;
      } else {
        first = listener;
      }
      return function unsubscribe() {
        if (!isSubscribed || first === null) return;
        isSubscribed = false;
        if (listener.next) {
          listener.next.prev = listener.prev;
        } else {
          last = listener.prev;
        }
        if (listener.prev) {
          listener.prev.next = listener.next;
        } else {
          first = listener.next;
        }
      };
    }
  };
}
var nullListeners = {
  notify: function notify() {},
  get: function get() {
    return [];
  }
};
function createSubscription(store, parentSub) {
  var unsubscribe;
  var listeners = nullListeners;
  var subscriptionsAmount = 0;
  var selfSubscribed = false;
  function addNestedSub(listener) {
    trySubscribe();
    var cleanupListener = listeners.subscribe(listener);
    var removed = false;
    return function () {
      if (!removed) {
        removed = true;
        cleanupListener();
        tryUnsubscribe();
      }
    };
  }
  function notifyNestedSubs() {
    listeners.notify();
  }
  function handleChangeWrapper() {
    if (subscription.onStateChange) {
      subscription.onStateChange();
    }
  }
  function isSubscribed() {
    return selfSubscribed;
  }
  function trySubscribe() {
    subscriptionsAmount++;
    if (!unsubscribe) {
      unsubscribe = parentSub ? parentSub.addNestedSub(handleChangeWrapper) : store.subscribe(handleChangeWrapper);
      listeners = createListenerCollection();
    }
  }
  function tryUnsubscribe() {
    subscriptionsAmount--;
    if (unsubscribe && subscriptionsAmount === 0) {
      unsubscribe();
      unsubscribe = void 0;
      listeners.clear();
      listeners = nullListeners;
    }
  }
  function trySubscribeSelf() {
    if (!selfSubscribed) {
      selfSubscribed = true;
      trySubscribe();
    }
  }
  function tryUnsubscribeSelf() {
    if (selfSubscribed) {
      selfSubscribed = false;
      tryUnsubscribe();
    }
  }
  var subscription = {
    addNestedSub: addNestedSub,
    notifyNestedSubs: notifyNestedSubs,
    handleChangeWrapper: handleChangeWrapper,
    isSubscribed: isSubscribed,
    trySubscribe: trySubscribeSelf,
    tryUnsubscribe: tryUnsubscribeSelf,
    getListeners: function getListeners() {
      return listeners;
    }
  };
  return subscription;
}

// src/utils/useIsomorphicLayoutEffect.ts
var canUseDOM = !!(typeof window !== "undefined" && typeof window.document !== "undefined" && typeof window.document.createElement !== "undefined");
var isReactNative = typeof navigator !== "undefined" && navigator.product === "ReactNative";
var useIsomorphicLayoutEffect = canUseDOM || isReactNative ? React.useLayoutEffect : React.useEffect;

// src/utils/shallowEqual.ts
function is(x, y) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y;
  }
}
function shallowEqual(objA, objB) {
  if (is(objA, objB)) return true;
  if ((0, _typeof2.default)(objA) !== "object" || objA === null || (0, _typeof2.default)(objB) !== "object" || objB === null) {
    return false;
  }
  var keysA = Object.keys(objA);
  var keysB = Object.keys(objB);
  if (keysA.length !== keysB.length) return false;
  for (var i = 0; i < keysA.length; i++) {
    if (!Object.prototype.hasOwnProperty.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }
  return true;
}

// src/utils/hoistStatics.ts
var REACT_STATICS = {
  childContextTypes: true,
  contextType: true,
  contextTypes: true,
  defaultProps: true,
  displayName: true,
  getDefaultProps: true,
  getDerivedStateFromError: true,
  getDerivedStateFromProps: true,
  mixins: true,
  propTypes: true,
  type: true
};
var KNOWN_STATICS = {
  name: true,
  length: true,
  prototype: true,
  caller: true,
  callee: true,
  arguments: true,
  arity: true
};
var FORWARD_REF_STATICS = {
  $$typeof: true,
  render: true,
  defaultProps: true,
  displayName: true,
  propTypes: true
};
var MEMO_STATICS = {
  $$typeof: true,
  compare: true,
  defaultProps: true,
  displayName: true,
  propTypes: true,
  type: true
};
var TYPE_STATICS = (0, _defineProperty2.default)((0, _defineProperty2.default)({}, ForwardRef, FORWARD_REF_STATICS), Memo, MEMO_STATICS);
function getStatics(component) {
  if (isMemo(component)) {
    return MEMO_STATICS;
  }
  return TYPE_STATICS[component["$$typeof"]] || REACT_STATICS;
}
var defineProperty = Object.defineProperty;
var getOwnPropertyNames = Object.getOwnPropertyNames;
var getOwnPropertySymbols = Object.getOwnPropertySymbols;
var getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
var getPrototypeOf = Object.getPrototypeOf;
var objectPrototype = Object.prototype;
function hoistNonReactStatics(targetComponent, sourceComponent) {
  if (typeof sourceComponent !== "string") {
    if (objectPrototype) {
      var inheritedComponent = getPrototypeOf(sourceComponent);
      if (inheritedComponent && inheritedComponent !== objectPrototype) {
        hoistNonReactStatics(targetComponent, inheritedComponent);
      }
    }
    var keys = getOwnPropertyNames(sourceComponent);
    if (getOwnPropertySymbols) {
      keys = keys.concat(getOwnPropertySymbols(sourceComponent));
    }
    var targetStatics = getStatics(targetComponent);
    var sourceStatics = getStatics(sourceComponent);
    for (var i = 0; i < keys.length; ++i) {
      var key = keys[i];
      if (!KNOWN_STATICS[key] && !(sourceStatics && sourceStatics[key]) && !(targetStatics && targetStatics[key])) {
        var descriptor = getOwnPropertyDescriptor(sourceComponent, key);
        try {
          defineProperty(targetComponent, key, descriptor);
        } catch (e) {}
      }
    }
  }
  return targetComponent;
}

// src/components/connect.tsx
var useSyncExternalStore = notInitialized;
var initializeConnect = function initializeConnect(fn) {
  useSyncExternalStore = fn;
};
var NO_SUBSCRIPTION_ARRAY = [null, null];
var stringifyComponent = function stringifyComponent(Comp) {
  try {
    return JSON.stringify(Comp);
  } catch (err) {
    return String(Comp);
  }
};
function useIsomorphicLayoutEffectWithArgs(effectFunc, effectArgs, dependencies) {
  useIsomorphicLayoutEffect(function () {
    return effectFunc.apply(void 0, (0, _toConsumableArray2.default)(effectArgs));
  }, dependencies);
}
function captureWrapperProps(lastWrapperProps, lastChildProps, renderIsScheduled, wrapperProps, childPropsFromStoreUpdate, notifyNestedSubs) {
  lastWrapperProps.current = wrapperProps;
  renderIsScheduled.current = false;
  if (childPropsFromStoreUpdate.current) {
    childPropsFromStoreUpdate.current = null;
    notifyNestedSubs();
  }
}
function subscribeUpdates(shouldHandleStateChanges, store, subscription, childPropsSelector, lastWrapperProps, lastChildProps, renderIsScheduled, isMounted, childPropsFromStoreUpdate, notifyNestedSubs, additionalSubscribeListener) {
  if (!shouldHandleStateChanges) return function () {};
  var didUnsubscribe = false;
  var lastThrownError = null;
  var checkForUpdates = function checkForUpdates() {
    if (didUnsubscribe || !isMounted.current) {
      return;
    }
    var latestStoreState = store.getState();
    var newChildProps, error;
    try {
      newChildProps = childPropsSelector(latestStoreState, lastWrapperProps.current);
    } catch (e) {
      error = e;
      lastThrownError = e;
    }
    if (!error) {
      lastThrownError = null;
    }
    if (newChildProps === lastChildProps.current) {
      if (!renderIsScheduled.current) {
        notifyNestedSubs();
      }
    } else {
      lastChildProps.current = newChildProps;
      childPropsFromStoreUpdate.current = newChildProps;
      renderIsScheduled.current = true;
      additionalSubscribeListener();
    }
  };
  subscription.onStateChange = checkForUpdates;
  subscription.trySubscribe();
  checkForUpdates();
  var unsubscribeWrapper = function unsubscribeWrapper() {
    didUnsubscribe = true;
    subscription.tryUnsubscribe();
    subscription.onStateChange = null;
    if (lastThrownError) {
      throw lastThrownError;
    }
  };
  return unsubscribeWrapper;
}
function strictEqual(a, b) {
  return a === b;
}
var hasWarnedAboutDeprecatedPureOption = false;
function connect(mapStateToProps, mapDispatchToProps, mergeProps) {
  var _ref6 = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
    pure = _ref6.pure,
    _ref6$areStatesEqual = _ref6.areStatesEqual,
    areStatesEqual = _ref6$areStatesEqual === void 0 ? strictEqual : _ref6$areStatesEqual,
    _ref6$areOwnPropsEqua = _ref6.areOwnPropsEqual,
    areOwnPropsEqual = _ref6$areOwnPropsEqua === void 0 ? shallowEqual : _ref6$areOwnPropsEqua,
    _ref6$areStatePropsEq = _ref6.areStatePropsEqual,
    areStatePropsEqual = _ref6$areStatePropsEq === void 0 ? shallowEqual : _ref6$areStatePropsEq,
    _ref6$areMergedPropsE = _ref6.areMergedPropsEqual,
    areMergedPropsEqual = _ref6$areMergedPropsE === void 0 ? shallowEqual : _ref6$areMergedPropsE,
    _ref6$forwardRef = _ref6.forwardRef,
    forwardRef = _ref6$forwardRef === void 0 ? false : _ref6$forwardRef,
    _ref6$context = _ref6.context,
    context = _ref6$context === void 0 ? ReactReduxContext : _ref6$context;
  if (process.env.NODE_ENV !== "production") {
    if (pure !== void 0 && !hasWarnedAboutDeprecatedPureOption) {
      hasWarnedAboutDeprecatedPureOption = true;
      warning('The `pure` option has been removed. `connect` is now always a "pure/memoized" component');
    }
  }
  var Context = context;
  var initMapStateToProps = mapStateToPropsFactory(mapStateToProps);
  var initMapDispatchToProps = mapDispatchToPropsFactory(mapDispatchToProps);
  var initMergeProps = mergePropsFactory(mergeProps);
  var shouldHandleStateChanges = Boolean(mapStateToProps);
  var wrapWithConnect = function wrapWithConnect(WrappedComponent) {
    if (process.env.NODE_ENV !== "production") {
      var isValid = /* @__PURE__ */isValidElementType(WrappedComponent);
      if (!isValid) throw new Error("You must pass a component to the function returned by connect. Instead received ".concat(stringifyComponent(WrappedComponent)));
    }
    var wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
    var displayName = "Connect(".concat(wrappedComponentName, ")");
    var selectorFactoryOptions = {
      shouldHandleStateChanges: shouldHandleStateChanges,
      displayName: displayName,
      wrappedComponentName: wrappedComponentName,
      WrappedComponent: WrappedComponent,
      // @ts-ignore
      initMapStateToProps: initMapStateToProps,
      // @ts-ignore
      initMapDispatchToProps: initMapDispatchToProps,
      initMergeProps: initMergeProps,
      areStatesEqual: areStatesEqual,
      areStatePropsEqual: areStatePropsEqual,
      areOwnPropsEqual: areOwnPropsEqual,
      areMergedPropsEqual: areMergedPropsEqual
    };
    function ConnectFunction(props) {
      var _React$useMemo = React.useMemo(function () {
          var reactReduxForwardedRef2 = props.reactReduxForwardedRef,
            wrapperProps2 = (0, _objectWithoutProperties2.default)(props, _excluded2);
          return [props.context, reactReduxForwardedRef2, wrapperProps2];
        }, [props]),
        _React$useMemo2 = (0, _slicedToArray2.default)(_React$useMemo, 3),
        propsContext = _React$useMemo2[0],
        reactReduxForwardedRef = _React$useMemo2[1],
        wrapperProps = _React$useMemo2[2];
      var ContextToUse = React.useMemo(function () {
        var ResultContext = Context;
        if (propsContext !== null && propsContext !== void 0 && propsContext.Consumer) {
          if (process.env.NODE_ENV !== "production") {
            var _isValid = /* @__PURE__ */isContextConsumer(
            // @ts-ignore
            /* @__PURE__ */
            React.createElement(propsContext.Consumer, null));
            if (!_isValid) {
              throw new Error("You must pass a valid React context consumer as `props.context`");
            }
            ResultContext = propsContext;
          }
        }
        return ResultContext;
      }, [propsContext, Context]);
      var contextValue = React.useContext(ContextToUse);
      var didStoreComeFromProps = Boolean(props.store) && Boolean(props.store.getState) && Boolean(props.store.dispatch);
      var didStoreComeFromContext = Boolean(contextValue) && Boolean(contextValue.store);
      if (process.env.NODE_ENV !== "production" && !didStoreComeFromProps && !didStoreComeFromContext) {
        throw new Error("Could not find \"store\" in the context of \"".concat(displayName, "\". Either wrap the root component in a <Provider>, or pass a custom React context provider to <Provider> and the corresponding React context consumer to ").concat(displayName, " in connect options."));
      }
      var store = didStoreComeFromProps ? props.store : contextValue.store;
      var getServerState = didStoreComeFromContext ? contextValue.getServerState : store.getState;
      var childPropsSelector = React.useMemo(function () {
        return finalPropsSelectorFactory(store.dispatch, selectorFactoryOptions);
      }, [store]);
      var _React$useMemo3 = React.useMemo(function () {
          if (!shouldHandleStateChanges) return NO_SUBSCRIPTION_ARRAY;
          var subscription2 = createSubscription(store, didStoreComeFromProps ? void 0 : contextValue.subscription);
          var notifyNestedSubs2 = subscription2.notifyNestedSubs.bind(subscription2);
          return [subscription2, notifyNestedSubs2];
        }, [store, didStoreComeFromProps, contextValue]),
        _React$useMemo4 = (0, _slicedToArray2.default)(_React$useMemo3, 2),
        subscription = _React$useMemo4[0],
        notifyNestedSubs = _React$useMemo4[1];
      var overriddenContextValue = React.useMemo(function () {
        if (didStoreComeFromProps) {
          return contextValue;
        }
        return _objectSpread(_objectSpread({}, contextValue), {}, {
          subscription: subscription
        });
      }, [didStoreComeFromProps, contextValue, subscription]);
      var lastChildProps = React.useRef(void 0);
      var lastWrapperProps = React.useRef(wrapperProps);
      var childPropsFromStoreUpdate = React.useRef(void 0);
      var renderIsScheduled = React.useRef(false);
      var isMounted = React.useRef(false);
      var latestSubscriptionCallbackError = React.useRef(void 0);
      useIsomorphicLayoutEffect(function () {
        isMounted.current = true;
        return function () {
          isMounted.current = false;
        };
      }, []);
      var actualChildPropsSelector = React.useMemo(function () {
        var selector = function selector() {
          if (childPropsFromStoreUpdate.current && wrapperProps === lastWrapperProps.current) {
            return childPropsFromStoreUpdate.current;
          }
          return childPropsSelector(store.getState(), wrapperProps);
        };
        return selector;
      }, [store, wrapperProps]);
      var subscribeForReact = React.useMemo(function () {
        var subscribe = function subscribe(reactListener) {
          if (!subscription) {
            return function () {};
          }
          return subscribeUpdates(shouldHandleStateChanges, store, subscription,
          // @ts-ignore
          childPropsSelector, lastWrapperProps, lastChildProps, renderIsScheduled, isMounted, childPropsFromStoreUpdate, notifyNestedSubs, reactListener);
        };
        return subscribe;
      }, [subscription]);
      useIsomorphicLayoutEffectWithArgs(captureWrapperProps, [lastWrapperProps, lastChildProps, renderIsScheduled, wrapperProps, childPropsFromStoreUpdate, notifyNestedSubs]);
      var actualChildProps;
      try {
        actualChildProps = useSyncExternalStore(
        // TODO We're passing through a big wrapper that does a bunch of extra side effects besides subscribing
        subscribeForReact,
        // TODO This is incredibly hacky. We've already processed the store update and calculated new child props,
        // TODO and we're just passing that through so it triggers a re-render for us rather than relying on `uSES`.
        actualChildPropsSelector, getServerState ? function () {
          return childPropsSelector(getServerState(), wrapperProps);
        } : actualChildPropsSelector);
      } catch (err) {
        if (latestSubscriptionCallbackError.current) {
          ;
          err.message += "\nThe error may be correlated with this previous error:\n".concat(latestSubscriptionCallbackError.current.stack, "\n\n");
        }
        throw err;
      }
      useIsomorphicLayoutEffect(function () {
        latestSubscriptionCallbackError.current = void 0;
        childPropsFromStoreUpdate.current = void 0;
        lastChildProps.current = actualChildProps;
      });
      var renderedWrappedComponent = React.useMemo(function () {
        return (
          // @ts-ignore
          /* @__PURE__ */
          React.createElement(WrappedComponent, _objectSpread(_objectSpread({}, actualChildProps), {}, {
            ref: reactReduxForwardedRef
          }))
        );
      }, [reactReduxForwardedRef, WrappedComponent, actualChildProps]);
      var renderedChild = React.useMemo(function () {
        if (shouldHandleStateChanges) {
          return /* @__PURE__ */React.createElement(ContextToUse.Provider, {
            value: overriddenContextValue
          }, renderedWrappedComponent);
        }
        return renderedWrappedComponent;
      }, [ContextToUse, renderedWrappedComponent, overriddenContextValue]);
      return renderedChild;
    }
    var _Connect = React.memo(ConnectFunction);
    var Connect = _Connect;
    Connect.WrappedComponent = WrappedComponent;
    Connect.displayName = ConnectFunction.displayName = displayName;
    if (forwardRef) {
      var _forwarded = React.forwardRef(function forwardConnectRef(props, ref) {
        return /* @__PURE__ */React.createElement(Connect, _objectSpread(_objectSpread({}, props), {}, {
          reactReduxForwardedRef: ref
        }));
      });
      var forwarded = _forwarded;
      forwarded.displayName = displayName;
      forwarded.WrappedComponent = WrappedComponent;
      return /* @__PURE__ */hoistNonReactStatics(forwarded, WrappedComponent);
    }
    return /* @__PURE__ */hoistNonReactStatics(Connect, WrappedComponent);
  };
  return wrapWithConnect;
}
var connect_default = exports.connect = connect;

// src/components/Provider.tsx
function Provider(_ref7) {
  var store = _ref7.store,
    context = _ref7.context,
    children = _ref7.children,
    serverState = _ref7.serverState,
    _ref7$stabilityCheck = _ref7.stabilityCheck,
    stabilityCheck = _ref7$stabilityCheck === void 0 ? "once" : _ref7$stabilityCheck,
    _ref7$identityFunctio = _ref7.identityFunctionCheck,
    identityFunctionCheck = _ref7$identityFunctio === void 0 ? "once" : _ref7$identityFunctio;
  var contextValue = React.useMemo(function () {
    var subscription = createSubscription(store);
    return {
      store: store,
      subscription: subscription,
      getServerState: serverState ? function () {
        return serverState;
      } : void 0,
      stabilityCheck: stabilityCheck,
      identityFunctionCheck: identityFunctionCheck
    };
  }, [store, serverState, stabilityCheck, identityFunctionCheck]);
  var previousState = React.useMemo(function () {
    return store.getState();
  }, [store]);
  useIsomorphicLayoutEffect(function () {
    var subscription = contextValue.subscription;
    subscription.onStateChange = subscription.notifyNestedSubs;
    subscription.trySubscribe();
    if (previousState !== store.getState()) {
      subscription.notifyNestedSubs();
    }
    return function () {
      subscription.tryUnsubscribe();
      subscription.onStateChange = void 0;
    };
  }, [contextValue, previousState]);
  var Context = context || ReactReduxContext;
  return /* @__PURE__ */React.createElement(Context.Provider, {
    value: contextValue
  }, children);
}
var Provider_default = exports.Provider = Provider;

// src/hooks/useStore.ts
function createStoreHook() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReactReduxContext;
  var useReduxContext2 = context === ReactReduxContext ? useReduxContext :
  // @ts-ignore
  createReduxContextHook(context);
  var useStore2 = function useStore2() {
    var _useReduxContext2 = useReduxContext2(),
      store = _useReduxContext2.store;
    return store;
  };
  Object.assign(useStore2, {
    withTypes: function withTypes() {
      return useStore2;
    }
  });
  return useStore2;
}
var useStore = exports.useStore = /* @__PURE__ */createStoreHook();

// src/hooks/useDispatch.ts
function createDispatchHook() {
  var context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : ReactReduxContext;
  var useStore2 = context === ReactReduxContext ? useStore : createStoreHook(context);
  var useDispatch2 = function useDispatch2() {
    var store = useStore2();
    return store.dispatch;
  };
  Object.assign(useDispatch2, {
    withTypes: function withTypes() {
      return useDispatch2;
    }
  });
  return useDispatch2;
}
var useDispatch = exports.useDispatch = /* @__PURE__ */createDispatchHook();

// src/exports.ts
var batch = exports.batch = defaultNoopBatch;

// src/index.ts
initializeUseSelector(_withSelector.useSyncExternalStoreWithSelector);
initializeConnect(React2.useSyncExternalStore);
