import axios from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  GET_COMPANY_DATA_FAILURE,
  GET_COMPANY_DATA_SUCCESS,
  GET_HOSTS_FAILURE,
  GET_HOSTS_SUCCESS,
} from "../actions/index";
import authSaga from "./authSaga";

const URL = "https://api.screencom.ch";

export function* FetchCompanyData(action) {
  try {
    const token = yield select((state) => state.user.token);
    console.log('Token from state in FetchCompanyData:', token);
    if (!token) {
      const accessToken = yield call(authSaga, { type: "GET_COMPANY_DATA_REQUEST" });
      if (accessToken) {
        yield put({ type: "SET_TOKEN", payload: accessToken });
      } else {
        throw new Error("Failed to fetch access token for user permissions.");
      }
    }
    if (token) {
      const res = yield axios.post(URL + "/api/load_company_data", action.payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      yield put(GET_COMPANY_DATA_SUCCESS(res.data[1]));
      console.log('API call successful, response company data:', res.data);
    } else {
      console.error('Failed to fetch access token for company.');
      throw new Error("Failed to fetch access token for company data.");
    }
  } catch (err) {
    console.error('Error in FetchCompanyData saga:', err);
    yield put(GET_COMPANY_DATA_FAILURE(err));
  }
}

export function* FetchHost(action) {
  try {
    const token = yield select((state) => state.user.token);
    console.log('Token from state in FetchHost:', token);
    
    if (!token) {
      console.log('No token found, calling authSaga to get access token...');
      const accessToken = yield call(authSaga, { type: "GET_HOSTS_REQUEST" });
      if (accessToken) {
        console.log('Access token retrieved:', accessToken);
        yield put({ type: "SET_TOKEN", payload: accessToken });
      } else {
        console.error('Failed to fetch access token for user permissions.');
        throw new Error("Failed to fetch access token for user permissions.");
      }
    }

    if (token) {
      console.log('Token is available, making API call to fetch hosts...');
      const res = yield axios.post(URL + "/teams/hosts", action.payload.payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log('API call successful, response data:', res.data);
      yield put(GET_HOSTS_SUCCESS(res.data.host));
    } else {
      console.error('Failed to fetch access token for host.');
      throw new Error("Failed to fetch access token for host.");
    }
  } catch (err) {
    console.error('Error in FetchHost saga:', err);
    yield put(GET_HOSTS_FAILURE(err));
  }
}

export default function* companySaga() {
  yield takeLatest("GET_COMPANY_DATA_REQUEST", FetchCompanyData);
  yield takeLatest("GET_HOSTS_REQUEST", FetchHost);
}
