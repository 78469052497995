import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../components/Spinner";
import Visitor from "../components/Visitor";
import { GET_VISITORS_REQUEST } from "../redux/actions";

const Visitors = forwardRef((_, ref) => {
  const dispatch = useDispatch();
  const allVisitors = useSelector((state) => state.visitors.data) || [];
  const existingAppointmentVisitors = useSelector((state) => state.getAppointment.data.visitors) || [];
  const hosts = useSelector((state) => state.company.data.hosts) || [];
  const companyDynamicFields = useSelector((state) => state.company.data.module_add_in_fields) || [];
  const visitorRefs = useRef([]);

  const [localSelectedVisitors, setLocalSelectedVisitors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (allVisitors.length === 0 && hosts.length > 0) {
      console.log("Dispatching GET_VISITORS_REQUEST");
      dispatch(GET_VISITORS_REQUEST());
    }
  }, [allVisitors, hosts]);

  useEffect(() => {
    const updateAttendees = () => {
      console.log("Updating attendees");
      dispatch(GET_VISITORS_REQUEST());
    };

    updateAttendees();

    const intervalId = setInterval(() => {
      updateAttendees();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    console.log("Hosts loaded: ", hosts);
    if (hosts.length > 0) {
      setIsLoading(true);
      const excludedHostEmails = new Set(hosts.map((host) => host.email));
      const existingAppointmentEmails = new Set(existingAppointmentVisitors.map((visitor) => visitor.email));

      const newSelectedVisitors = allVisitors.reduce((acc, visitor) => {
        const isExcluded = excludedHostEmails.has(visitor.email);
        const existingVisitor = existingAppointmentVisitors.find((ev) => ev.email === visitor.email) || {};
        const existingSelected = localSelectedVisitors.find((v) => v.email === visitor.email);

        if (!isExcluded) {
          if (existingSelected) {
            acc.push(existingSelected);
          } else {
            acc.push({
              ...visitor,
              ...existingVisitor,
              isChecked: existingAppointmentEmails.has(visitor.email)
                ? true
                : existingVisitor.isChecked ?? visitor.isChecked,
              dynamicFields: existingVisitor.additional_information ?? companyDynamicFields,
            });
          }
        }
        return acc;
      }, []);

      setLocalSelectedVisitors(newSelectedVisitors);
      setIsLoading(false);
    }
  }, [allVisitors, existingAppointmentVisitors, hosts, companyDynamicFields]);

  const handleCheckedChange = (visitorData) => {
    setLocalSelectedVisitors((prev) =>
      prev.map((visitor) => (visitor.email === visitorData.email ? { ...visitor, ...visitorData } : visitor)),
    );
  };

  const createAppointmentVisitors = () => {
    const validVisitors = visitorRefs.current
      .map((visitorRef) => {
        if (visitorRef && visitorRef.getVisitorData) {
          return visitorRef.getVisitorData();
        }
        return null;
      })
      .filter((visitor) => visitor && visitor.isChecked);

    dispatch({
      type: "SET_APPOINTMENT_DATA",
      payload: {
        visitors: validVisitors.map((visitor) => ({
          name: visitor.name,
          email: visitor.email,
          company: visitor.company,
          additional_information: visitor.dynamicFields,
        })),
      },
    });
  };

  useImperativeHandle(ref, () => ({
    triggerValidation: () => {
      let allValid = true;
      let allMissingFields = [];
      visitorRefs.current.forEach((visitorRef, index) => {
        if (visitorRef && visitorRef.triggerValidation) {
          const result = visitorRef.triggerValidation();
          if (!result.isValid) {
            allValid = false;
            allMissingFields = allMissingFields.concat(result.missingFields);
          }
        } else {
          allValid = false;
        }
      });
      console.log("Overall validation result:", allValid);
      return { isValid: allValid, missingFields: allMissingFields };
    },
    createAppointmentVisitors,
    getVisitorsData: () => {
      return visitorRefs.current.map((visitorRef) => visitorRef && visitorRef.getVisitorData()).filter(Boolean);
    },
  }));

  return (
    <div>
      <span className="block text-sm font-inter_semibold mt-4">Teilnehmer</span>
      {isLoading ? (
        <Spinner size="small" />
      ) : localSelectedVisitors.length > 0 ? (
        <>
          <span className="text-sm text-gray font-inter_regular">
            Wählen Sie alle Teilnehmer aus, welche eine Einladung erhalten sollten.
          </span>
          {localSelectedVisitors.map((visitor, index) => (
            <Visitor
              key={visitor.email}
              ref={(el) => {
                visitorRefs.current[index] = el;
              }}
              initialName={visitor.name}
              initialEmail={visitor.email}
              initialCompany={visitor.company}
              initiallyChecked={visitor.isChecked}
              dynamicFields={visitor.dynamicFields}
              onCheckedChange={handleCheckedChange}
            />
          ))}
        </>
      ) : (
        <span className="text-sm text-gray font-inter_regular">Bitte fügen Sie einen Teilnehmer zum Termin hinzu.</span>
      )}
    </div>
  );
});

export default Visitors;
