/*! @azure/msal-common v14.13.0 2024-07-01 */
'use strict';

var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AADAuthorityConstants", {
  enumerable: true,
  get: function get() {
    return _Constants.AADAuthorityConstants;
  }
});
exports.AADServerParamKeys = void 0;
Object.defineProperty(exports, "AccountEntity", {
  enumerable: true,
  get: function get() {
    return _AccountEntity.AccountEntity;
  }
});
Object.defineProperty(exports, "AuthError", {
  enumerable: true,
  get: function get() {
    return _AuthError.AuthError;
  }
});
exports.AuthErrorCodes = void 0;
Object.defineProperty(exports, "AuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _AuthError.AuthErrorMessage;
  }
});
exports.AuthToken = void 0;
Object.defineProperty(exports, "AuthenticationHeaderParser", {
  enumerable: true,
  get: function get() {
    return _AuthenticationHeaderParser.AuthenticationHeaderParser;
  }
});
Object.defineProperty(exports, "AuthenticationScheme", {
  enumerable: true,
  get: function get() {
    return _Constants.AuthenticationScheme;
  }
});
Object.defineProperty(exports, "Authority", {
  enumerable: true,
  get: function get() {
    return _Authority.Authority;
  }
});
exports.AuthorityFactory = void 0;
Object.defineProperty(exports, "AuthorityType", {
  enumerable: true,
  get: function get() {
    return _AuthorityType.AuthorityType;
  }
});
Object.defineProperty(exports, "AuthorizationCodeClient", {
  enumerable: true,
  get: function get() {
    return _AuthorizationCodeClient.AuthorizationCodeClient;
  }
});
Object.defineProperty(exports, "AzureCloudInstance", {
  enumerable: true,
  get: function get() {
    return _AuthorityOptions.AzureCloudInstance;
  }
});
Object.defineProperty(exports, "BaseClient", {
  enumerable: true,
  get: function get() {
    return _BaseClient.BaseClient;
  }
});
Object.defineProperty(exports, "CacheAccountType", {
  enumerable: true,
  get: function get() {
    return _Constants.CacheAccountType;
  }
});
Object.defineProperty(exports, "CacheError", {
  enumerable: true,
  get: function get() {
    return _CacheError.CacheError;
  }
});
exports.CacheHelpers = exports.CacheErrorCodes = void 0;
Object.defineProperty(exports, "CacheManager", {
  enumerable: true,
  get: function get() {
    return _CacheManager.CacheManager;
  }
});
Object.defineProperty(exports, "CacheOutcome", {
  enumerable: true,
  get: function get() {
    return _Constants.CacheOutcome;
  }
});
Object.defineProperty(exports, "CacheType", {
  enumerable: true,
  get: function get() {
    return _Constants.CacheType;
  }
});
Object.defineProperty(exports, "CcsCredentialType", {
  enumerable: true,
  get: function get() {
    return _CcsCredential.CcsCredentialType;
  }
});
Object.defineProperty(exports, "ClaimsRequestKeys", {
  enumerable: true,
  get: function get() {
    return _Constants.ClaimsRequestKeys;
  }
});
exports.ClientAssertionUtils = void 0;
Object.defineProperty(exports, "ClientAuthError", {
  enumerable: true,
  get: function get() {
    return _ClientAuthError.ClientAuthError;
  }
});
exports.ClientAuthErrorCodes = void 0;
Object.defineProperty(exports, "ClientAuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _ClientAuthError.ClientAuthErrorMessage;
  }
});
Object.defineProperty(exports, "ClientConfigurationError", {
  enumerable: true,
  get: function get() {
    return _ClientConfigurationError.ClientConfigurationError;
  }
});
exports.ClientConfigurationErrorCodes = void 0;
Object.defineProperty(exports, "ClientConfigurationErrorMessage", {
  enumerable: true,
  get: function get() {
    return _ClientConfigurationError.ClientConfigurationErrorMessage;
  }
});
Object.defineProperty(exports, "CodeChallengeMethodValues", {
  enumerable: true,
  get: function get() {
    return _Constants.CodeChallengeMethodValues;
  }
});
Object.defineProperty(exports, "Constants", {
  enumerable: true,
  get: function get() {
    return _Constants.Constants;
  }
});
Object.defineProperty(exports, "CredentialType", {
  enumerable: true,
  get: function get() {
    return _Constants.CredentialType;
  }
});
Object.defineProperty(exports, "DEFAULT_CRYPTO_IMPLEMENTATION", {
  enumerable: true,
  get: function get() {
    return _ICrypto.DEFAULT_CRYPTO_IMPLEMENTATION;
  }
});
Object.defineProperty(exports, "DEFAULT_SYSTEM_OPTIONS", {
  enumerable: true,
  get: function get() {
    return _ClientConfiguration.DEFAULT_SYSTEM_OPTIONS;
  }
});
Object.defineProperty(exports, "DEFAULT_TOKEN_RENEWAL_OFFSET_SEC", {
  enumerable: true,
  get: function get() {
    return _Constants.DEFAULT_TOKEN_RENEWAL_OFFSET_SEC;
  }
});
Object.defineProperty(exports, "DefaultStorageClass", {
  enumerable: true,
  get: function get() {
    return _CacheManager.DefaultStorageClass;
  }
});
Object.defineProperty(exports, "Errors", {
  enumerable: true,
  get: function get() {
    return _Constants.Errors;
  }
});
Object.defineProperty(exports, "GrantType", {
  enumerable: true,
  get: function get() {
    return _Constants.GrantType;
  }
});
Object.defineProperty(exports, "HeaderNames", {
  enumerable: true,
  get: function get() {
    return _Constants.HeaderNames;
  }
});
Object.defineProperty(exports, "HttpStatus", {
  enumerable: true,
  get: function get() {
    return _Constants.HttpStatus;
  }
});
Object.defineProperty(exports, "IntFields", {
  enumerable: true,
  get: function get() {
    return _PerformanceEvent.IntFields;
  }
});
Object.defineProperty(exports, "InteractionRequiredAuthError", {
  enumerable: true,
  get: function get() {
    return _InteractionRequiredAuthError.InteractionRequiredAuthError;
  }
});
exports.InteractionRequiredAuthErrorCodes = void 0;
Object.defineProperty(exports, "InteractionRequiredAuthErrorMessage", {
  enumerable: true,
  get: function get() {
    return _InteractionRequiredAuthError.InteractionRequiredAuthErrorMessage;
  }
});
Object.defineProperty(exports, "JoseHeader", {
  enumerable: true,
  get: function get() {
    return _JoseHeader.JoseHeader;
  }
});
Object.defineProperty(exports, "JsonWebTokenTypes", {
  enumerable: true,
  get: function get() {
    return _Constants.JsonWebTokenTypes;
  }
});
Object.defineProperty(exports, "LogLevel", {
  enumerable: true,
  get: function get() {
    return _Logger.LogLevel;
  }
});
Object.defineProperty(exports, "Logger", {
  enumerable: true,
  get: function get() {
    return _Logger.Logger;
  }
});
Object.defineProperty(exports, "NetworkManager", {
  enumerable: true,
  get: function get() {
    return _NetworkManager.NetworkManager;
  }
});
Object.defineProperty(exports, "OIDC_DEFAULT_SCOPES", {
  enumerable: true,
  get: function get() {
    return _Constants.OIDC_DEFAULT_SCOPES;
  }
});
Object.defineProperty(exports, "ONE_DAY_IN_MS", {
  enumerable: true,
  get: function get() {
    return _Constants.ONE_DAY_IN_MS;
  }
});
Object.defineProperty(exports, "PasswordGrantConstants", {
  enumerable: true,
  get: function get() {
    return _Constants.PasswordGrantConstants;
  }
});
Object.defineProperty(exports, "PerformanceClient", {
  enumerable: true,
  get: function get() {
    return _PerformanceClient.PerformanceClient;
  }
});
Object.defineProperty(exports, "PerformanceEventStatus", {
  enumerable: true,
  get: function get() {
    return _PerformanceEvent.PerformanceEventStatus;
  }
});
Object.defineProperty(exports, "PerformanceEvents", {
  enumerable: true,
  get: function get() {
    return _PerformanceEvent.PerformanceEvents;
  }
});
Object.defineProperty(exports, "PersistentCacheKeys", {
  enumerable: true,
  get: function get() {
    return _Constants.PersistentCacheKeys;
  }
});
Object.defineProperty(exports, "PopTokenGenerator", {
  enumerable: true,
  get: function get() {
    return _PopTokenGenerator.PopTokenGenerator;
  }
});
Object.defineProperty(exports, "PromptValue", {
  enumerable: true,
  get: function get() {
    return _Constants.PromptValue;
  }
});
Object.defineProperty(exports, "ProtocolMode", {
  enumerable: true,
  get: function get() {
    return _ProtocolMode.ProtocolMode;
  }
});
Object.defineProperty(exports, "ProtocolUtils", {
  enumerable: true,
  get: function get() {
    return _ProtocolUtils.ProtocolUtils;
  }
});
Object.defineProperty(exports, "RefreshTokenClient", {
  enumerable: true,
  get: function get() {
    return _RefreshTokenClient.RefreshTokenClient;
  }
});
Object.defineProperty(exports, "RequestParameterBuilder", {
  enumerable: true,
  get: function get() {
    return _RequestParameterBuilder.RequestParameterBuilder;
  }
});
Object.defineProperty(exports, "ResponseHandler", {
  enumerable: true,
  get: function get() {
    return _ResponseHandler.ResponseHandler;
  }
});
Object.defineProperty(exports, "ResponseMode", {
  enumerable: true,
  get: function get() {
    return _Constants.ResponseMode;
  }
});
Object.defineProperty(exports, "ScopeSet", {
  enumerable: true,
  get: function get() {
    return _ScopeSet.ScopeSet;
  }
});
Object.defineProperty(exports, "ServerError", {
  enumerable: true,
  get: function get() {
    return _ServerError.ServerError;
  }
});
Object.defineProperty(exports, "ServerResponseType", {
  enumerable: true,
  get: function get() {
    return _Constants.ServerResponseType;
  }
});
Object.defineProperty(exports, "ServerTelemetryManager", {
  enumerable: true,
  get: function get() {
    return _ServerTelemetryManager.ServerTelemetryManager;
  }
});
Object.defineProperty(exports, "SilentFlowClient", {
  enumerable: true,
  get: function get() {
    return _SilentFlowClient.SilentFlowClient;
  }
});
Object.defineProperty(exports, "StringUtils", {
  enumerable: true,
  get: function get() {
    return _StringUtils.StringUtils;
  }
});
Object.defineProperty(exports, "StubPerformanceClient", {
  enumerable: true,
  get: function get() {
    return _StubPerformanceClient.StubPerformanceClient;
  }
});
Object.defineProperty(exports, "StubbedNetworkModule", {
  enumerable: true,
  get: function get() {
    return _INetworkModule.StubbedNetworkModule;
  }
});
Object.defineProperty(exports, "THE_FAMILY_ID", {
  enumerable: true,
  get: function get() {
    return _Constants.THE_FAMILY_ID;
  }
});
Object.defineProperty(exports, "ThrottlingConstants", {
  enumerable: true,
  get: function get() {
    return _Constants.ThrottlingConstants;
  }
});
Object.defineProperty(exports, "ThrottlingUtils", {
  enumerable: true,
  get: function get() {
    return _ThrottlingUtils.ThrottlingUtils;
  }
});
exports.TimeUtils = void 0;
Object.defineProperty(exports, "TokenCacheContext", {
  enumerable: true,
  get: function get() {
    return _TokenCacheContext.TokenCacheContext;
  }
});
Object.defineProperty(exports, "UrlString", {
  enumerable: true,
  get: function get() {
    return _UrlString.UrlString;
  }
});
exports.UrlUtils = void 0;
Object.defineProperty(exports, "buildAccountToCache", {
  enumerable: true,
  get: function get() {
    return _ResponseHandler.buildAccountToCache;
  }
});
Object.defineProperty(exports, "buildClientInfo", {
  enumerable: true,
  get: function get() {
    return _ClientInfo.buildClientInfo;
  }
});
Object.defineProperty(exports, "buildClientInfoFromHomeAccountId", {
  enumerable: true,
  get: function get() {
    return _ClientInfo.buildClientInfoFromHomeAccountId;
  }
});
Object.defineProperty(exports, "buildStaticAuthorityOptions", {
  enumerable: true,
  get: function get() {
    return _Authority.buildStaticAuthorityOptions;
  }
});
Object.defineProperty(exports, "buildTenantProfileFromIdTokenClaims", {
  enumerable: true,
  get: function get() {
    return _AccountInfo.buildTenantProfileFromIdTokenClaims;
  }
});
Object.defineProperty(exports, "createAuthError", {
  enumerable: true,
  get: function get() {
    return _AuthError.createAuthError;
  }
});
Object.defineProperty(exports, "createClientAuthError", {
  enumerable: true,
  get: function get() {
    return _ClientAuthError.createClientAuthError;
  }
});
Object.defineProperty(exports, "createClientConfigurationError", {
  enumerable: true,
  get: function get() {
    return _ClientConfigurationError.createClientConfigurationError;
  }
});
Object.defineProperty(exports, "createInteractionRequiredAuthError", {
  enumerable: true,
  get: function get() {
    return _InteractionRequiredAuthError.createInteractionRequiredAuthError;
  }
});
Object.defineProperty(exports, "formatAuthorityUri", {
  enumerable: true,
  get: function get() {
    return _Authority.formatAuthorityUri;
  }
});
Object.defineProperty(exports, "getClientAssertion", {
  enumerable: true,
  get: function get() {
    return ClientAssertionUtils.getClientAssertion;
  }
});
Object.defineProperty(exports, "getTenantIdFromIdTokenClaims", {
  enumerable: true,
  get: function get() {
    return _TokenClaims.getTenantIdFromIdTokenClaims;
  }
});
Object.defineProperty(exports, "invoke", {
  enumerable: true,
  get: function get() {
    return _FunctionWrappers.invoke;
  }
});
Object.defineProperty(exports, "invokeAsync", {
  enumerable: true,
  get: function get() {
    return _FunctionWrappers.invokeAsync;
  }
});
Object.defineProperty(exports, "tenantIdMatchesHomeTenant", {
  enumerable: true,
  get: function get() {
    return _AccountInfo.tenantIdMatchesHomeTenant;
  }
});
Object.defineProperty(exports, "updateAccountTenantProfileData", {
  enumerable: true,
  get: function get() {
    return _AccountInfo.updateAccountTenantProfileData;
  }
});
Object.defineProperty(exports, "version", {
  enumerable: true,
  get: function get() {
    return _packageMetadata.version;
  }
});
var AuthToken = _interopRequireWildcard(require("./account/AuthToken.js"));
exports.AuthToken = AuthToken;
var AuthorityFactory = _interopRequireWildcard(require("./authority/AuthorityFactory.js"));
exports.AuthorityFactory = AuthorityFactory;
var CacheHelpers = _interopRequireWildcard(require("./cache/utils/CacheHelpers.js"));
exports.CacheHelpers = CacheHelpers;
var TimeUtils = _interopRequireWildcard(require("./utils/TimeUtils.js"));
exports.TimeUtils = TimeUtils;
var UrlUtils = _interopRequireWildcard(require("./utils/UrlUtils.js"));
exports.UrlUtils = UrlUtils;
var ClientAssertionUtils = _interopRequireWildcard(require("./utils/ClientAssertionUtils.js"));
exports.ClientAssertionUtils = ClientAssertionUtils;
var AADServerParamKeys = _interopRequireWildcard(require("./constants/AADServerParamKeys.js"));
exports.AADServerParamKeys = AADServerParamKeys;
var _AuthorizationCodeClient = require("./client/AuthorizationCodeClient.js");
var _RefreshTokenClient = require("./client/RefreshTokenClient.js");
var _SilentFlowClient = require("./client/SilentFlowClient.js");
var _BaseClient = require("./client/BaseClient.js");
var _ClientConfiguration = require("./config/ClientConfiguration.js");
var _AccountInfo = require("./account/AccountInfo.js");
var _TokenClaims = require("./account/TokenClaims.js");
var _CcsCredential = require("./account/CcsCredential.js");
var _ClientInfo = require("./account/ClientInfo.js");
var _Authority = require("./authority/Authority.js");
var _AuthorityOptions = require("./authority/AuthorityOptions.js");
var _AuthorityType = require("./authority/AuthorityType.js");
var _ProtocolMode = require("./authority/ProtocolMode.js");
var _CacheManager = require("./cache/CacheManager.js");
var _AccountEntity = require("./cache/entities/AccountEntity.js");
var _TokenCacheContext = require("./cache/persistence/TokenCacheContext.js");
var _INetworkModule = require("./network/INetworkModule.js");
var _NetworkManager = require("./network/NetworkManager.js");
var _ThrottlingUtils = require("./network/ThrottlingUtils.js");
var _UrlString = require("./url/UrlString.js");
var _ICrypto = require("./crypto/ICrypto.js");
var _JoseHeader = require("./crypto/JoseHeader.js");
var _RequestParameterBuilder = require("./request/RequestParameterBuilder.js");
var _ResponseHandler = require("./response/ResponseHandler.js");
var _ScopeSet = require("./request/ScopeSet.js");
var _AuthenticationHeaderParser = require("./request/AuthenticationHeaderParser.js");
var _Logger = require("./logger/Logger.js");
var _InteractionRequiredAuthError = require("./error/InteractionRequiredAuthError.js");
var _AuthError = require("./error/AuthError.js");
var _ServerError = require("./error/ServerError.js");
var _CacheError = require("./error/CacheError.js");
var _ClientAuthError = require("./error/ClientAuthError.js");
var _ClientConfigurationError = require("./error/ClientConfigurationError.js");
var _Constants = require("./utils/Constants.js");
var _StringUtils = require("./utils/StringUtils.js");
var _ProtocolUtils = require("./utils/ProtocolUtils.js");
var _FunctionWrappers = require("./utils/FunctionWrappers.js");
var _ServerTelemetryManager = require("./telemetry/server/ServerTelemetryManager.js");
var _PerformanceEvent = require("./telemetry/performance/PerformanceEvent.js");
var _PerformanceClient = require("./telemetry/performance/PerformanceClient.js");
var _StubPerformanceClient = require("./telemetry/performance/StubPerformanceClient.js");
var _PopTokenGenerator = require("./crypto/PopTokenGenerator.js");
var _packageMetadata = require("./packageMetadata.js");
var InteractionRequiredAuthErrorCodes = _interopRequireWildcard(require("./error/InteractionRequiredAuthErrorCodes.js"));
exports.InteractionRequiredAuthErrorCodes = InteractionRequiredAuthErrorCodes;
var AuthErrorCodes = _interopRequireWildcard(require("./error/AuthErrorCodes.js"));
exports.AuthErrorCodes = AuthErrorCodes;
var CacheErrorCodes = _interopRequireWildcard(require("./error/CacheErrorCodes.js"));
exports.CacheErrorCodes = CacheErrorCodes;
var ClientAuthErrorCodes = _interopRequireWildcard(require("./error/ClientAuthErrorCodes.js"));
exports.ClientAuthErrorCodes = ClientAuthErrorCodes;
var ClientConfigurationErrorCodes = _interopRequireWildcard(require("./error/ClientConfigurationErrorCodes.js"));
exports.ClientConfigurationErrorCodes = ClientConfigurationErrorCodes;
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
