import "./error-polyfill"
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';
import 'raf/polyfill';
import 'fast-text-encoding';
import 'classlist-polyfill';

import { MsalProvider } from '@azure/msal-react';
import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import pcaInstance from './login/msalInstance.js';

/* REDUX */
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./redux/reducers/rootReducer";
import rootSaga from "./redux/sagas/rootSaga";

/* global document, Office, module, require */

const title = "ePortier";

const rootElement = document.getElementById("container");
const root = createRoot(rootElement);

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const isDevelopment = "development" === "development";
const composeEnhancers =
  isDevelopment && typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));

sagaMiddleware.run(rootSaga);

/* Render application after Office initializes */
Office.onReady(() => {
  root.render(
    <React.StrictMode>
      <MsalProvider instance={pcaInstance}>
        <Provider store={store}>
          <App title={title} officeReady={true} />
        </Provider>
      </MsalProvider>
    </React.StrictMode>
  );
});

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    root.render(
      <React.StrictMode>
        <MsalProvider instance={pcaInstance}>
          <Provider store={store}>
            <NextApp/>
          </Provider>
        </MsalProvider>
      </React.StrictMode>
    );
  });
}
