import * as React from "react";

const VisitorInput = ({ className, error, ...props }) => {
  const errorClass = error ? "ring-2 ring-red-500" : "";

  return (
    <input
      className={`relative mt-2 w-full rounded-md bg-white py-1.5 px-3 shadow-sm ring-1 ring-lightgrey text-sm ${errorClass} ${
        className || ""
      }`}
      {...props}
    />
  );
};

export default VisitorInput;
