import { call, put, takeLatest } from "redux-saga/effects";
import { GET_VISITORS_FAILURE, GET_VISITORS_SUCCESS } from "../actions/index";

function* fetchVisitorsOffice() {
  try {
    const visitors = yield call(getAllAttendeesFromOffice);
    console.log("Fetched visitors: ", visitors);  // Log visitors
    yield put(GET_VISITORS_SUCCESS(visitors));
  } catch (e) {
    console.error("Failed to fetch visitors: ", e);  // Log error
    yield put(GET_VISITORS_FAILURE(e.message));
  }
}

function* watchFetchVisitorsRequest() {
  yield takeLatest("GET_VISITORS_REQUEST", fetchVisitorsOffice);
}

export const visitorsDataSaga = watchFetchVisitorsRequest;

function getAllAttendeesFromOffice() {
  return new Promise((resolve, reject) => {
    let allAttendees = [];

    Office.context.mailbox.item.requiredAttendees.getAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
        allAttendees = allAttendees.concat(
          asyncResult.value.map((attendee) => ({
            name: attendee.displayName,
            email: attendee.emailAddress,
            isChecked: true,
          })),
        );

        Office.context.mailbox.item.optionalAttendees.getAsync((optionalAsyncResult) => {
          if (optionalAsyncResult.status === Office.AsyncResultStatus.Succeeded) {
            allAttendees = allAttendees.concat(
              optionalAsyncResult.value.map((attendee) => ({
                name: attendee.displayName,
                email: attendee.emailAddress,
                isChecked: false,
              })),
            );
            resolve(allAttendees);
          } else {
            reject(new Error("Fehler beim Abrufen optionaler Teilnehmer"));
          }
        });
      } else {
        reject(new Error("Fehler beim Abrufen erforderlicher Teilnehmer"));
      }
    });
  });
}
