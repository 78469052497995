import { SET_TOKEN } from "../actions";

const initState = {
  loaded: false,
  data: [],
  errors: [],
  authMethod: "SSO",
  token: null,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, data: action.payload };
    case "FETCH_PERMISSIONS_USER_REQUEST":
      return { ...state, loaded: false };
    case "FETCH_PERMISSIONS_USER_SUCCESS":
      return { ...state, loaded: true, data: action.payload };
    case "FETCH_PERMISSIONS_USER_FAILURE":
      return { ...state, loaded: true, errors: action.payload };
    case "SET_AUTH_METHOD":
      return { ...state, authMethod: action.payload };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
