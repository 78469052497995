import { useEffect } from "react";
import { useDispatch } from "react-redux";

export function useFetchOutlookIdOnLoad() {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAndStoreOutlookId(dispatch);
  }, [dispatch]);
}

export function fetchAndStoreOutlookId(dispatch) {
    getEventOutlookUid((err, eventOutlookUid) => {
      if (err) {
        console.error("Error fetching Outlook UID: " + err.message);
      } else if (eventOutlookUid) {
        console.log("Fetched Outlook UID: ", eventOutlookUid);
        dispatch(setAppointmentData(eventOutlookUid));
      } else {
        console.warn("EventOutlookUid is null. Trying to save and get the ID.");
        Office.context.mailbox.item.saveAsync(function (result) {
          if (result.status === Office.AsyncResultStatus.Succeeded) {
            console.log(`saveAsync succeeded, itemId is ${result.value}`);
            dispatch(setAppointmentData(result.value));
          } else {
            console.error(`saveAsync failed with message: ${result.error.message}`);
          }
        });
      }
    });
}

function setAppointmentData(outlookId) {
  return {
    type: "SET_APPOINTMENT_DATA",
    payload: {
      outlook_id: outlookId,
    },
  };
}

function getEventOutlookUid(callback) {
  if (!Office.context || !Office.context.mailbox || !Office.context.mailbox.item) {
    console.error("Office.context.mailbox.item is not available.");
    return callback(new Error("Office.context.mailbox.item is not available"), null);
  }

  const item = Office.context.mailbox.item;
  console.log("Office.context.mailbox.item is available.");

  if (typeof item.getItemIdAsync === "function") {
    console.log("Calling getItemIdAsync...");
    item.getItemIdAsync(function (result) {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        console.log("getItemIdAsync succeeded, itemId is: ", result.value);
        callback(null, result.value);
      } else {
        console.warn(`getItemIdAsync failed: ${result.error.message}. Probably just a new event`);
        callback(null, null);
      }
    });
  } else if (item.itemId) {
    console.log("Using Office.context.mailbox.item.itemId directly.");
    callback(null, item.itemId);
  } else {
    console.warn("Neither getItemIdAsync nor itemId are available. Trying to use saveAsync as fallback.");
    callback(null, null);
  }
}
