import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../components/Dropdown";
import { GET_HOSTS_REQUEST } from "../redux/actions/index";

const Location = () => {
  const [selectedLocation, setSelectedLocation] = useState("");

  const locations = useSelector((state) => state.company.data.locations || []);
  const existingLocationName = useSelector((state) => state.getAppointment.data.location_name);
  const dispatch = useDispatch();

  useEffect(() => {
    const dropdownOptions = locations.map((location) => ({
      value: location.id.toString(),
      label: location.location_name,
    }));

    const initialLocation =
      dropdownOptions.find((option) => option.label === existingLocationName) ||
      (dropdownOptions.length > 0 ? dropdownOptions[0] : null);
    if (initialLocation) {
      setSelectedLocation(initialLocation.value);
      dispatch({
        type: "SET_APPOINTMENT_DATA",
        payload: { location_id: initialLocation.value },
      });
      dispatch(GET_HOSTS_REQUEST({ payload: { location_id: initialLocation.value, details: false } }));
    }
  }, [locations, existingLocationName, dispatch]);

  const handleDropdownChange = (selectedValue) => {
    setSelectedLocation(selectedValue);
    dispatch({
      type: "SET_APPOINTMENT_DATA",
      payload: { location_id: selectedValue },
    });
    dispatch(GET_HOSTS_REQUEST({ payload: { location_id: selectedValue, details: false } }));
  };

  return (
    <Dropdown
      label="Standort"
      options={locations.map((location) => ({ value: location.id.toString(), label: location.location_name }))}
      onChange={handleDropdownChange}
      value={selectedLocation}
    />
  );
};

export default Location;
