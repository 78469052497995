/*! @azure/msal-browser v2.39.0 2024-06-06 */
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BrowserCacheManager", {
  enumerable: true,
  get: function get() {
    return _BrowserCacheManager.BrowserCacheManager;
  }
});
Object.defineProperty(exports, "BrowserConstants", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.BrowserConstants;
  }
});
Object.defineProperty(exports, "BrowserPerformanceClient", {
  enumerable: true,
  get: function get() {
    return _BrowserPerformanceClient.BrowserPerformanceClient;
  }
});
Object.defineProperty(exports, "BrowserPerformanceMeasurement", {
  enumerable: true,
  get: function get() {
    return _BrowserPerformanceMeasurement.BrowserPerformanceMeasurement;
  }
});
Object.defineProperty(exports, "CryptoOps", {
  enumerable: true,
  get: function get() {
    return _CryptoOps.CryptoOps;
  }
});
Object.defineProperty(exports, "EventHandler", {
  enumerable: true,
  get: function get() {
    return _EventHandler.EventHandler;
  }
});
Object.defineProperty(exports, "NativeAuthError", {
  enumerable: true,
  get: function get() {
    return _NativeAuthError.NativeAuthError;
  }
});
Object.defineProperty(exports, "NativeInteractionClient", {
  enumerable: true,
  get: function get() {
    return _NativeInteractionClient.NativeInteractionClient;
  }
});
Object.defineProperty(exports, "NativeMessageHandler", {
  enumerable: true,
  get: function get() {
    return _NativeMessageHandler.NativeMessageHandler;
  }
});
Object.defineProperty(exports, "PopupClient", {
  enumerable: true,
  get: function get() {
    return _PopupClient.PopupClient;
  }
});
Object.defineProperty(exports, "RedirectClient", {
  enumerable: true,
  get: function get() {
    return _RedirectClient.RedirectClient;
  }
});
Object.defineProperty(exports, "RedirectHandler", {
  enumerable: true,
  get: function get() {
    return _RedirectHandler.RedirectHandler;
  }
});
Object.defineProperty(exports, "SilentCacheClient", {
  enumerable: true,
  get: function get() {
    return _SilentCacheClient.SilentCacheClient;
  }
});
Object.defineProperty(exports, "SilentIframeClient", {
  enumerable: true,
  get: function get() {
    return _SilentIframeClient.SilentIframeClient;
  }
});
Object.defineProperty(exports, "SilentRefreshClient", {
  enumerable: true,
  get: function get() {
    return _SilentRefreshClient.SilentRefreshClient;
  }
});
Object.defineProperty(exports, "StandardInteractionClient", {
  enumerable: true,
  get: function get() {
    return _StandardInteractionClient.StandardInteractionClient;
  }
});
Object.defineProperty(exports, "TemporaryCacheKeys", {
  enumerable: true,
  get: function get() {
    return _BrowserConstants.TemporaryCacheKeys;
  }
});
var _BrowserCacheManager = require("./cache/BrowserCacheManager.js");
var _StandardInteractionClient = require("./interaction_client/StandardInteractionClient.js");
var _RedirectClient = require("./interaction_client/RedirectClient.js");
var _PopupClient = require("./interaction_client/PopupClient.js");
var _SilentIframeClient = require("./interaction_client/SilentIframeClient.js");
var _SilentCacheClient = require("./interaction_client/SilentCacheClient.js");
var _SilentRefreshClient = require("./interaction_client/SilentRefreshClient.js");
var _NativeInteractionClient = require("./interaction_client/NativeInteractionClient.js");
var _RedirectHandler = require("./interaction_handler/RedirectHandler.js");
var _EventHandler = require("./event/EventHandler.js");
var _NativeMessageHandler = require("./broker/nativeBroker/NativeMessageHandler.js");
var _BrowserConstants = require("./utils/BrowserConstants.js");
var _CryptoOps = require("./crypto/CryptoOps.js");
var _NativeAuthError = require("./error/NativeAuthError.js");
var _BrowserPerformanceClient = require("./telemetry/BrowserPerformanceClient.js");
var _BrowserPerformanceMeasurement = require("./telemetry/BrowserPerformanceMeasurement.js");
