const initState = {
  loaded: true,
  data: [],
  errors: [],
  changeappointmentStatus: null,
};

const changeAppointmentReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_USER":
      return { ...state, data: action.payload };
    case "CHANGE_APPOINTMENT_REQUEST":
      return { ...state, loaded: false };
    case "CHANGE_APPOINTMENT_SUCCESS":
      return { ...state, loaded: true, data: action.payload, changeappointmentStatus: "success" };
    case "CHANGE_APPOINTMENT_FAILURE":
      return { ...state, loaded: true, errors: action.payload, changeappointmentStatus: "failure" };
    default:
      return state;
  }
};

export default changeAppointmentReducer;
