import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "../components/Input";

const DateTime = () => {
  const [date, setDate] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endTime, setEndTime] = useState("");
  const dispatch = useDispatch();

  const pad = (number) => {
    return number < 10 ? '0' + number : number;
  };

  const updateDateTime = () => {
    if (Office.context && Office.context.mailbox && Office.context.mailbox.item && Office.context.mailbox.item.start) {

      Office.context.mailbox.item.start.getAsync((result) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          const appointmentStart = new Date(result.value);
          const formattedDate = appointmentStart.toLocaleDateString("de-DE", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          const formattedTime = appointmentStart.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          });
          setDate(formattedDate);
          setArrivalTime(formattedTime);

          const dateTimeForRedux = appointmentStart.getFullYear() + '-' + pad(appointmentStart.getMonth() + 1) + '-' + pad(appointmentStart.getDate()) + 'T' + pad(appointmentStart.getHours()) + ':' + pad(appointmentStart.getMinutes()) + ':' + pad(appointmentStart.getSeconds());

          Office.context.mailbox.item.end.getAsync((endResult) => {
            if (endResult.status === Office.AsyncResultStatus.Succeeded) {
              const appointmentEnd = new Date(endResult.value);
              const formattedEndDate = appointmentEnd.toLocaleDateString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
              const formattedEndTime = appointmentEnd.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              });
              setEndDate(formattedEndDate);
              setEndTime(formattedEndTime);

              const endDateTimeForRedux = appointmentEnd.getFullYear() + '-' + pad(appointmentEnd.getMonth() + 1) + '-' + pad(appointmentEnd.getDate()) + 'T' + pad(appointmentEnd.getHours()) + ':' + pad(appointmentEnd.getMinutes()) + ':' + pad(appointmentEnd.getSeconds());

              dispatch({
                type: "SET_APPOINTMENT_DATA",
                payload: { date_time: dateTimeForRedux, end_date_time: endDateTimeForRedux },
              });

              console.log("Start Time:", dateTimeForRedux);
              console.log("End Time:", endDateTimeForRedux);
            } else {
              console.error(`End time fetch failed with message ${endResult.error.message}`);
            }
          });
        } else {
          console.error(`Start time fetch failed with message ${result.error.message}`);
        }
      });
    } else {
      console.warn('Office.context.mailbox.item.start or end is not available.');
    }
  };

  useEffect(() => {
    updateDateTime();

    const intervalId = setInterval(() => {
      updateDateTime();
    }, 2000); 

    return () => {
      clearInterval(intervalId); 
    };
  }, []);

  return (
    <Input
      label="Datum und Zeit"
      value={`${date} ${arrivalTime} - ${endDate} ${endTime}`}
      className="pointer-events-none"
      readOnly
    />
  );
};

export default DateTime;
