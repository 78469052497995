import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateTime from "../components/Date";
import Host from "../components/Host";
import Location from "../components/Location";
import Visitors from "../components/Visitors";
import { fetchAndStoreOutlookId, useFetchOutlookIdOnLoad } from "../helpers/appointmentIdHelpers";
import { CHANGE_APPOINTMENT_REQUEST, CREATE_APPOINTMENT_REQUEST } from "../redux/actions";
import AppointmentDeleted from "./AppointmentDeleted";
import AppointmentFailed from "./AppointmentFailed";
import AppointmentSuccess from "./AppointmentSuccess";
import AppointmentUpdateSuccess from "./AppointmentUpdateSuccess"; // Importieren Sie die neue Komponente

const Portier = () => {
  const dispatch = useDispatch();
  const appointment = useSelector((state) => state.appointment);
  const emailStatus = useSelector((state) => state.mailstatus.emailStatus);
  const deleteAppointmentStatus = useSelector((state) => state.deleteAppointment.deleteAppointmentStatus);
  const appointmentStatus = useSelector((state) => state.createAppointment.appointmentStatus);
  const isGetAppointmentSuccessful = useSelector((state) => state.getAppointment.isSuccessful);
  const getAppointment = useSelector((state) => state.getAppointment);
  const changeVisitorStatus = useSelector((state) => state.changeVisitor.changevisitorStatus);
  const addVisitorStatus = useSelector((state) => state.addVisitor.addvisitorStatus);
  const changeAppointmentStatus = useSelector((state) => state.changeAppointment.changeappointmentStatus);

  const [showAppointmentSuccess, setShowAppointmentSuccess] = useState(false);
  const [showAppointmentFailure, setShowAppointmentFailure] = useState(false);
  const [showAppointmentDeleted, setShowAppointmentDeleted] = useState(false);
  const [showAppointmentUpdateSuccess, setShowAppointmentUpdateSuccess] = useState(false);
  const [isVisitorsSaved, setIsVisitorsSaved] = useState(false);

  const visitorsRef = useRef(null);

  useFetchOutlookIdOnLoad();

  useEffect(() => {
    if (appointment.outlook_id) {
      dispatch({
        type: "GET_APPOINTMENT_REQUEST",
        payload: { outlook_id: appointment.outlook_id },
      });
    }
  }, [appointment.outlook_id]);

  const validateVisitors = () => {
    if (visitorsRef.current && visitorsRef.current.triggerValidation) {
      const validationResults = visitorsRef.current.triggerValidation();
      if (!validationResults.isValid) {
        alert("Bitte füllen Sie alle erforderlichen Felder aus: " + validationResults.missingFields.join(", "));
        return false;
      }
      visitorsRef.current.createAppointmentVisitors();
    } else {
    }
    return true;
  };

  const handleButtonClickSend = async () => {
    console.log("Button click handled...");
    if (isGetAppointmentSuccessful) {
      console.log("Appointment already exists. Updating...");
      dispatch(CHANGE_APPOINTMENT_REQUEST(appointment));
    } else {
      if (validateVisitors()) {
        console.log("Visitors validated, creating appointment...");
        await fetchAndStoreOutlookId(dispatch);
        setIsVisitorsSaved(true);
      } else {
        console.log("Validation failed, not proceeding.");
      }
    }
  };

  useEffect(() => {
    if (deleteAppointmentStatus === "success") {
      setShowAppointmentDeleted(true);
      setShowAppointmentFailure(false);
      setShowAppointmentSuccess(false);
      setShowAppointmentUpdateSuccess(false);
    } else if (deleteAppointmentStatus === "failure") {
      setShowAppointmentFailure(true);
      setShowAppointmentDeleted(false);
      setShowAppointmentSuccess(false);
      setShowAppointmentUpdateSuccess(false);
    }
  }, [deleteAppointmentStatus]);

  useEffect(() => {
    if (appointmentStatus === "success") {
      setShowAppointmentSuccess(true);
      setShowAppointmentFailure(false);
      setShowAppointmentUpdateSuccess(false);
    } else if (appointmentStatus === "failure") {
      setShowAppointmentFailure(true);
      setShowAppointmentSuccess(false);
      setShowAppointmentUpdateSuccess(false);
    }
  }, [appointmentStatus]);

  useEffect(() => {
    if (emailStatus === "success") {
      setShowAppointmentSuccess(true);
      setShowAppointmentFailure(false);
      setShowAppointmentUpdateSuccess(false);
    } else if (emailStatus === "failure") {
      setShowAppointmentFailure(true);
      setShowAppointmentSuccess(false);
      setShowAppointmentUpdateSuccess(false);
    }
  }, [emailStatus]);

  useEffect(() => {
    if (changeVisitorStatus === "success" || addVisitorStatus === "success" || changeAppointmentStatus === "success") {
      setShowAppointmentUpdateSuccess(true);
      setShowAppointmentSuccess(false);
      setShowAppointmentFailure(false);
      setShowAppointmentDeleted(false);
    } else if (
      changeVisitorStatus === "failure" ||
      addVisitorStatus === "failure" ||
      changeAppointmentStatus === "failure"
    ) {
      setShowAppointmentFailure(true);
      setShowAppointmentUpdateSuccess(false);
      setShowAppointmentSuccess(false);
      setShowAppointmentDeleted(false);
    }
  }, [changeVisitorStatus, addVisitorStatus, changeAppointmentStatus]);

  const deepCompareFields = (fields1, fields2) => {
    if (fields1.length !== fields2.length) {
      return false;
    }

    fields1.sort((a, b) => a.field_id.localeCompare(b.field_id));
    fields2.sort((a, b) => a.field_id.localeCompare(b.field_id));

    for (let i = 0; i < fields1.length; i++) {
      const field1 = fields1[i];
      const field2 = fields2[i];
      if (
        field1.type !== field2.type ||
        field1.title !== field2.title ||
        field1.field_id !== field2.field_id ||
        field1.is_shown.join(",") !== field2.is_shown.join(",") ||
        field1.field_type !== field2.field_type ||
        field1.inserted_value !== field2.inserted_value ||
        field1.accepted_values.join(",") !== field2.accepted_values.join(",")
      ) {
        return false;
      }
    }
    return true;
  };

  const handleButtonClickUpdate = async () => {
    if (isGetAppointmentSuccessful) {
      console.log("Existiert Bereits!");
      const originalVisitors = getAppointment.data.visitors || [];
      const updatedVisitors = visitorsRef.current.getVisitorsData();

      // Changed visitors
      const changedVisitors = updatedVisitors.filter((visitor) => {
        const originalVisitor = originalVisitors.find((ov) => ov.email === visitor.email);
        return (
          originalVisitor &&
          (originalVisitor.name !== visitor.name ||
            originalVisitor.company !== visitor.company ||
            !deepCompareFields(originalVisitor.additional_information, visitor.dynamicFields))
        );
      });

      // New visitors
      const newVisitors = updatedVisitors.filter(
        (visitor) => !originalVisitors.find((ov) => ov.email === visitor.email) && visitor.isChecked,
      );

      // Removed visitors
      const removedVisitors = originalVisitors.filter(
        (originalVisitor) =>
          !updatedVisitors.find((uv) => uv.email === originalVisitor.email) ||
          !updatedVisitors.find((uv) => uv.email === originalVisitor.email).isChecked,
      );

      let isValid = true;
      if (changedVisitors.length > 0) {
        console.log("Updating visitors data...", changedVisitors);
        isValid = validateVisitors() && isValid;
        if (isValid) {
          dispatch({
            type: "CHANGE_VISITOR_DATA_REQUEST",
            payload: {
              outlook_id: appointment.outlook_id,
              visitors: changedVisitors.map((visitor) => ({
                id: originalVisitors.find((ov) => ov.email === visitor.email)?.visitor_id,
                name: visitor.name,
                email: visitor.email,
                company: visitor.company,
                additional_information: visitor.dynamicFields.map((field) => ({
                  type: field.type || field.info_type,
                  title: field.title,
                  field_id: field.field_id,
                  is_shown: field.is_shown,
                  field_type: field.field_type,
                  inserted_value: field.value,
                  accepted_values: field.accepted_values,
                })),
              })),
            },
          });
        } else {
          console.log("Validation failed for changed visitors.");
        }
      }

      if (newVisitors.length > 0) {
        console.log("Adding new visitors...", newVisitors);
        isValid = validateVisitors() && isValid;
        if (isValid) {
          dispatch({
            type: "ADD_VISITOR_TO_APPOINTMENT_REQUEST",
            payload: {
              outlook_id: appointment.outlook_id,
              visitors: newVisitors.map((visitor) => ({
                name: visitor.name,
                email: visitor.email,
                company: visitor.company,
                additional_information: visitor.dynamicFields.map((field) => ({
                  type: field.type || field.info_type, // Ensure type is included
                  title: field.title,
                  field_id: field.field_id,
                  is_shown: field.is_shown,
                  field_type: field.field_type,
                  inserted_value: field.value,
                  accepted_values: field.accepted_values,
                })),
              })),
            },
          });
        } else {
          console.log("Validation failed for new visitors.");
        }
      }

      if (removedVisitors.length > 0) {
        console.log("Removing visitors...", removedVisitors);
        removedVisitors.forEach((visitor) => {
          dispatch({
            type: "DELETE_VISITOR_FROM_APPOINTMENT_REQUEST",
            payload: {
              outlook_id: appointment.outlook_id,
              visitor_id: visitor.visitor_id,
            },
          });
        });
      }

      if (isValid) {
        dispatch(CHANGE_APPOINTMENT_REQUEST(appointment));
      }
    } else {
      console.log("existiert nicht!");
    }
  };

  const deleteButtonClick = async () => {
    if (appointment.outlook_id) {
      dispatch({ type: "DELETE_APPOINTMENT_REQUEST", payload: { outlook_id: appointment.outlook_id } });
      console.log("Appointment gelöscht!");
    } else {
      console.log("Keine outlook_id verfügbar zum Löschen");
    }
  };

  useEffect(() => {
    if (isVisitorsSaved && appointment.outlook_id && validateVisitors()) {
      dispatch(CREATE_APPOINTMENT_REQUEST(appointment));
      setIsVisitorsSaved(false);
    }
  }, [isVisitorsSaved, appointment.outlook_id, dispatch]);

  const formattedDate = getAppointment?.data?.created_at
    ? new Date(getAppointment.data.created_at).toLocaleDateString("de-DE")
    : null;
  const formattedTime = getAppointment?.data?.created_at
    ? new Date(getAppointment.data.created_at).toLocaleTimeString("de-DE").slice(0, -3)
    : null;

  return (
    <div className="px-4 pb-4">
      {showAppointmentSuccess && <AppointmentSuccess />}
      {showAppointmentFailure && <AppointmentFailed />}
      {showAppointmentUpdateSuccess && <AppointmentUpdateSuccess />}
      {showAppointmentDeleted && <AppointmentDeleted />}
      {isGetAppointmentSuccessful &&
        !showAppointmentDeleted &&
        !showAppointmentUpdateSuccess &&
        !showAppointmentFailure &&
        !showAppointmentSuccess && (
          <span className="inline-flex items-center w-full rounded-md bg-screencom bg-opacity-50 px-2 py-1 text-xs text-black font-inter_medium ring-1 ring-inset ring-screencom">
            Einladung versendet am {formattedDate} / {formattedTime} Uhr
          </span>
        )}
      {!showAppointmentSuccess &&
        !showAppointmentFailure &&
        !showAppointmentUpdateSuccess &&
        !showAppointmentDeleted && (
          <>
            <DateTime />
            <Location />
            <Host />
            <Visitors ref={visitorsRef} />
            <div className="mb-12 pt-5"></div>
            <div className="w-full bg-white shadow p-4 mt-20 fixed font-inter_semibold inset-x-0 bottom-0 flex">
              {!isGetAppointmentSuccessful && (
                <button
                  type="button"
                  onClick={handleButtonClickSend}
                  className="w-full relative text-white bg-screencom rounded-md text-base mr-2 px-5 py-2.5 disabled:cursor-not-allowed disabled:opacity-40"
                >
                  Einladung senden
                </button>
              )}
              {isGetAppointmentSuccessful && (
                <button
                  type="button"
                  onClick={handleButtonClickUpdate}
                  className="w-full relative text-white bg-screencom rounded-md text-base mr-2 px-5 py-2.5 disabled:cursor-not-allowed disabled:opacity-40"
                >
                  Aktualisieren
                </button>
              )}
              {isGetAppointmentSuccessful && (
                <button
                  className="inline-flex items-center px-5 py-2.5 bg-red-600 text-white text-sm font-medium rounded-md"
                  onClick={deleteButtonClick}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                    />
                  </svg>
                </button>
              )}
            </div>
          </>
        )}
    </div>
  );
};

export default Portier;
