/*! @azure/msal-browser v2.39.0 2024-06-06 */
'use strict';

/* eslint-disable header/header */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.version = exports.name = void 0;
var name = exports.name = "@azure/msal-browser";
var version = exports.version = "2.39.0";
